#clinical-studies {
  br {
    display: none;

    @include mq('large') {
      display: block;
    }
  }

  .breadcrumb-wrapper {
    background-color: rgb(131, 129, 135, 0.06);
    padding-top: 30px;
    margin-top: 76px;
    padding-bottom: 31px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    align-items: center;
    margin-bottom: 44px;

    @include mq('large') {
      margin-bottom: 35px;
      margin-top: 170px;
      padding-left: 81px;
      padding-right: 80px;
    }

    > .text:first-child {
      flex: 1;
    }

    a {
      text-decoration: none;
      font-size: var(--emu-common-font-sizes-wide-medium);
      line-height: var(--emu-common-line-heights-wide-medium);
      color: var(--emu-semantic-colors-primary-purple-300);
      font-weight: var(--emu-common-font-weight-regular);
      display: inline-flex;
      align-items: center;
      gap: 10px;
      font-weight: var(--emu-common-font-weight-regular);
      line-height: normal;
    }

    ul {
      display: flex;
      list-style: none;
      padding-left: var(--emu-common-spacing-none);

      @include mq('large') {
      }

      li {
        font-size: var(--emu-common-font-sizes-wide-medium);
        font-weight: var(--emu-common-font-weight-light);
        line-height: var(--emu-common-line-heights-wide-large);

        a {
          color: var(--emu-semantic-colors-primary-purple-200);

          &::after {
            font-weight: var(--emu-common-font-weight-light);
            color: var(--emu-semantic-colors-primary-purple-400);
            content: '>';
            padding-right: 10px;
          }
        }
      }
    }

    img {
      width: 13px;
    }
  }

  .clinical-study {
    &__intro-text {
      margin-top: 17px;

      @include mq('large') {
        margin-top: 33px;
        margin-bottom: 81px;
      }

      p {
        font-size: var(--emu-semantic-font-sizes-narrow-large);
        line-height: 28px;
        font-weight: var(--emu-common-font-weight-regular);
        letter-spacing: -1px;
        color: var(--emu-semantic-colors-primary-purple-300);
      }
    }

    &__endpoint {
      &-container {
        margin-top: var(--emu-common-spacing-large);
        margin-bottom: 37px;

        &--secondary {
          margin-bottom: 25px;
        }
      }

      &-desc {
        @include mq('large') {
          margin-bottom: 43px;
        }

        h2 {
          font: var(--emu-semantic-typography-narrow-headings-xl);
          margin-bottom: 17px;

          @include mq('large') {
            font-size: var(--emu-semantic-font-sizes-wide-xl);
            line-height: normal;
            margin-bottom: 35px;
          }
        }

        h3 {
          font-size: var(--emu-semantic-font-sizes-narrow-large);
          line-height: var(--emu-semantic-line-heights-narrow-medium);
          margin-bottom: 11px;
          letter-spacing: -1px;

          @include mq('large') {
            margin-bottom: 24px;
            font-size: 28px;
          }
        }

        p {
          font-size: var(--emu-semantic-font-sizes-narrow-large);
          line-height: 28px;
          font-weight: var(--emu-common-font-weight-regular);
          letter-spacing: -1px;
          color: var(--emu-semantic-colors-primary-purple-300);
        }
      }
    }

    &__table {
      br {
        display: block;
      }

      &-title {
        h2,
        h3,
        h4 {
          font-size: 24px;
          line-height: 32px;
          letter-spacing: -1px;
          margin-bottom: 5px;

          @include mq('large') {
            font-size: 36px;
            line-height: normal;
            margin-bottom: 41px;
          }
        }
      }

      &-disclaimer {
        font-size: 14px;
        line-height: 21px;
        margin-top: 27px;
        color: var(--emu-semantic-colors-primary-purple-400);

        @include mq('large') {
          margin-top: 25px;
        }
      }

      &-container {
        &--adverse-reactions {
          .content {
            // media required to match the design
            @include mq('375px') {
              white-space: nowrap;
            }

            @include mq('medium') {
              white-space: unset;
            }
          }

          .clinical-study__table {
            &-disclaimer {
              margin-bottom: 44px;

              @include mq('large') {
                margin-top: 49px;
                font-size: 18px;
                line-height: 24px;
                margin-bottom: 83px;
              }
            }

            th {
              @include mq('large') {
                padding-top: 29px;
                padding-bottom: 27px;
              }
            }

            tr {
              @include mq('large') {
                td {
                  color: var(--emu-common-colors-black);

                  &:first-child {
                    color: var(--emu-common-colors-black);
                  }
                }
              }
            }
          }
        }
      }
    }

    &__graph {
      &-container {
        border-radius: var(--emu-common-border-radius-medium);
        border: var(--emu-common-border-width-thin) solid rgba(#838187, 0.3); // color from design has opacity
        padding-top: 27px;
        padding-right: 20px;
        padding-bottom: var(--emu-common-spacing-large);
        padding-left: 20px;

        @include mq('large') {
          padding-top: 44px;
          padding-right: 47px;
          padding-bottom: 45px;
          padding-left: 47px;
        }

        &--improvement {
          @include mq('large') {
            padding-right: 54px;
            padding-left: 54px;
          }
        }
      }

      &-title {
        font-size: 21px;
        font-weight: var(--emu-common-font-weight-regular);
        line-height: normal;
        color: var(--emu-semantic-colors-primary-purple-300);
        margin-bottom: 28px;

        @include mq('large') {
          font-size: 24px;
          margin-bottom: 44px;
        }
      }
    }

    &__img-with-text {
      padding-bottom: 27px;
      border-bottom: var(--emu-common-border-width-thin) solid
        rgba(#838187, 0.3);
      margin-bottom: 23px;

      @include mq('large') {
        display: flex;
        gap: 30px;
        align-items: center;
        padding-bottom: 50px;
        margin-bottom: 50px;
      }

      > .image {
        width: 298px; // from design
        border-radius: 50%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 25px;
        overflow: hidden;

        @include mq('large') {
          flex-basis: 298px;
          margin-bottom: var(--emu-common-spacing-none);
        }
      }

      > .container {
        @include mq('large') {
          flex: 1;
        }
      }

      &-title {
        text-align: center;
        margin-bottom: 11px;

        @include mq('large') {
          text-align: left;
          margin-bottom: 24px;
        }

        h2,
        h3,
        h4 {
          font-size: 21px;
          line-height: var(--emu-semantic-line-heights-narrow-medium);
          letter-spacing: -1px;

          @include mq('large') {
            font-size: 28px;
          }
        }
      }

      &-desc {
        text-align: center;
        margin-bottom: 49px;
        font-size: 21px;
        line-height: var(--emu-semantic-line-heights-narrow-medium);

        @include mq('large') {
          text-align: left;
          margin-bottom: 20px;
        }
      }

      &-disclaimer {
        font-size: 14px;
        line-height: 21px;
        color: var(--emu-semantic-colors-primary-purple-400);

        sup {
          margin-left: -5px;
        }

        p:not(:last-child) {
          margin-bottom: 15px;

          @include mq('large') {
            margin-bottom: 17px;
          }
        }

        .emphasis {
          line-height: normal;
        }
      }
    }

    &__consistency-text {
      margin-bottom: 26px;

      h2 {
        margin-bottom: 19px;

        @include mq('large') {
          font-size: var(--emu-semantic-font-sizes-wide-xl);
          line-height: normal;
          margin-bottom: 35px;
          letter-spacing: -1px;
        }
      }

      h3 {
        font-size: 21px;
        line-height: var(--emu-semantic-line-heights-narrow-medium);
        letter-spacing: -1px;
        margin-bottom: 19px;

        @include mq('large') {
          font-size: 28px;
          margin-bottom: 23px;
        }
      }

      p {
        margin-bottom: 17px;

        @include mq('large') {
          margin-bottom: 38px;
        }
      }
    }

    &__disclaimer {
      &-container {
        margin-top: 27px;
        margin-bottom: 36px;

        @include mq('large') {
          margin-top: 48px;
          margin-bottom: 104px;
        }
      }

      &-text {
        margin-top: 18px;
        color: var(--emu-common-colors-black);

        @include mq('large') {
          margin-top: var(--emu-common-spacing-medium);
        }
      }
    }
  }
}
