.article {
  padding-top: 80px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: -4px;

  @include mq('large') {
    padding-top: 197px;
    padding-bottom: 28px;
    display: flex;
    padding-right: 79px;
    padding-left: 80px;
    margin-bottom: var(--emu-common-spacing-none);

    > .container:nth-child(1) {
      flex: 1;
    }
  }

  .news__wrapper--list {
    display: none;

    @include mq('large') {
      display: block;
    }
  }
}
