#practice-support {
  .common-teaser {
    &__wrapper {
      @include mq('large') {
        padding-bottom: 33px;
      }

      .aaaem-teaser {
        .cmp-teaser__description {
          margin-left: var(--emu-common-spacing-none);
          margin-right: var(--emu-common-spacing-none);
        }
      }
    }
  }

  .practice-support {
    &__steps {
      margin-top: 24px;
      margin-bottom: 48px;
      text-align: center;

      @include mq('large') {
        text-align: left;
        margin-top: 21px;
        margin-bottom: 37px;
      }

      a {
        text-decoration: none;
        color: var(--emu-semantic-colors-primary-purple-200);
      }

      .heading-primary {
        text-align: center;

        @include mq('large') {
          text-align: left;
          line-height: normal;

          h2 {
            line-height: normal;
          }
        }
      }

      .custom-select__main {
        margin-top: 9px;

        .ss-single {
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      &-main {
        display: flex;
        position: relative;
        flex-direction: column;
        padding-bottom: 24px;
        border-bottom: var(--emu-common-border-width-thin) solid
          var(--emu-semantic-colors-secondary-dark-gray-300);

        @include mq('large') {
          padding-bottom: 38px;
          flex-direction: row;
          gap: 30px;

          > .container {
            flex: 1;
            width: calc(33.33% - 20px);
          }
        }
      }

      &-title {
        letter-spacing: -1px;
        margin-bottom: 11px;
        margin-top: 17px;

        h2,
        h3,
        h4 {
          font-size: var(--emu-semantic-font-sizes-narrow-large);
        }

        @include mq('large') {
          margin-bottom: 23px;
          h2,
          h3,
          h4 {
            font-size: var(--emu-semantic-font-sizes-wide-large);
            line-height: var(--emu-semantic-line-heights-narrow-medium);
          }
        }
      }

      &-text {
        p,
        li {
          &:not(:last-child) {
            margin-bottom: 19px;

            @include mq('large') {
              margin-bottom: var(--emu-common-spacing-medium);
            }
          }
        }
      }

      &-query {
        &-container {
          position: relative;
          margin-top: 19px;

          @include mq('large') {
            margin-top: 22px;
          }
        }

        &-content {
          opacity: 0;
          transition: all 0.3s linear;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          pointer-events: none;

          .emphasis {
            display: inline-block;
          }

          &--show {
            position: static;
            opacity: 1;
            pointer-events: initial;
          }
        }
      }
    }

    &__aa-resources {
      text-align: center;

      @include mq('large') {
        text-align: left;
      }

      .heading-primary {
        h2,
        h3,
        h4 {
          @include mq('large') {
            line-height: normal;
          }
        }
      }

      &-main {
        display: flex;
        flex-direction: column;
        margin-top: 24px;
        margin-bottom: 50px;
        gap: 44px;

        @include mq('large') {
          flex-direction: row;
          gap: 130px;
          flex-wrap: wrap;
          margin-top: 38px;
          margin-bottom: 70px;
        }

        > .container {
          @include mq('large') {
            &:nth-child(1) {
              flex: 1;
            }

            &:nth-child(2) {
              width: 407px;
            }
          }
        }
      }

      &-disclaimer {
        margin-top: 27px;
        text-align: left;
        text-indent: -4px;

        @include mq('large') {
          margin-top: 55px;
          padding: var(--emu-common-spacing-none);
          margin-bottom: var(--emu-common-spacing-none);
        }
      }

      &-card {
        &-img {
          &--lg {
            img {
              max-height: 90px;
            }
          }

          &--regular {
            img {
              max-height: 60px;
            }
          }

          img {
            margin-left: auto;
            margin-right: auto;

            @include mq('large') {
              margin-left: var(--emu-common-spacing-none);
              margin-right: var(--emu-common-spacing-none);
            }
          }
        }

        &-title {
          h2,
          h3,
          h4 {
            font-size: var(--emu-semantic-font-sizes-narrow-large);
            font-weight: var(--emu-common-font-weight-regular);
            line-height: 28px;
            margin-top: 26px;
            margin-bottom: 17px;
            letter-spacing: -1px;

            @include mq('large') {
              margin-top: 27px;
            }
          }

          &--alle-for-business {
            h2,
            h3,
            h4 {
              @include mq('large') {
                margin-bottom: 12px;
              }
            }
          }
        }

        &-cta {
          margin-top: 25px;

          @include mq('large') {
            margin-top: 32px;
          }
        }

        &--dark {
          padding-top: 50px;
          padding-right: 20px;
          padding-bottom: 50px;
          padding-left: 20px;
          background-color: var(--emu-semantic-colors-primary-purple-400);
          border-radius: var(--emu-common-border-radius-medium);

          @include mq('large') {
            padding-left: 60px;
            padding-right: 50px;
            margin-bottom: var(--emu-common-spacing-none);
          }

          .practice-support__aa-resources-card {
            &-title {
              h2,
              h3,
              h4 {
                color: var(--emu-common-colors-white);
                margin-bottom: 24px;
                line-height: 24px;
                font-weight: var(--emu-common-font-weight-bold);

                @include mq('large') {
                  font-size: var(--emu-semantic-font-sizes-wide-large);
                  line-height: var(--emu-semantic-line-heights-wide-medium);
                }
              }
            }

            &-text {
              color: var(--emu-common-colors-white);
            }

            &-cta {
              @include mq('large') {
                margin-top: 34px;
              }
            }
          }
        }
      }

      &-section {
        &--left {
          > .container {
            &:first-child {
              margin-bottom: 50px;

              @include mq('large') {
                margin-bottom: 60px;
              }
            }
          }
        }
      }

      &-carousel {
        &.aaaem-carousel {
          padding-top: var(--emu-common-spacing-none);
          padding-bottom: 55px;
          text-align: center;

          .aaaem-carousel {
            &__actions {
              position: initial;
            }

            &__action {
              bottom: 0;
              position: absolute;
            }

            &__indicators {
              bottom: 16px;
              top: auto;
              left: 60px;
              width: calc(100% - 120px);
            }
          }
        }

        &-container {
          padding-top: 50px;
          padding-right: 24px;
          padding-bottom: 50px;
          padding-left: 24px;
          border-radius: var(--emu-common-border-radius-medium);
          background: linear-gradient(
            180deg,
            var(--emu-semantic-colors-secondary-gold-200) 0%,
            rgba(187, 169, 205, 0.54) 27.2%,
            rgba(149, 119, 191, 0.37) 50.8%,
            rgba(185, 171, 213, 0.63) 75.73%,
            var(--emu-semantic-colors-primary-purple-100) 100%
          );
          margin-bottom: 20px;

          @include mq('large') {
            margin-bottom: 26px;
            padding-left: 60px;
            padding-right: 60px;
          }
        }

        &-title {
          h3,
          h4,
          h5 {
            font-size: 42px;
            font-weight: var(--emu-common-font-weight-regular);
            letter-spacing: -2px;
            line-height: normal;
            margin-top: 6px;
            margin-bottom: 6px;
            color: var(--emu-semantic-colors-primary-purple-400);
          }
        }

        &-img {
          img {
            margin-left: auto;
            margin-right: auto;
          }
        }

        &-text {
          min-height: 48px;

          sup {
            left: 2px;
          }
        }

        &-cta {
          margin-top: 27px;
        }
      }
    }

    &__brands {
      &-grid {
        padding-top: 46px;
        padding-bottom: 50px;
        padding-left: 20px;
        padding-right: 20px;
        display: flex;
        background-color: rgba(131, 129, 135, 0.06);
        gap: 46px;
        flex-direction: column;
        text-align: center;

        @include mq('large') {
          gap: 30px;
          padding-top: 96px;
          padding-bottom: 100px;
          padding-left: 80px;
          padding-right: 80px;
          flex-direction: row;
          height: 100%;
          text-align: left;
        }
      }

      &-item {
        @include mq('large') {
          flex-direction: column;
          height: 100%;
          display: flex;
          max-width: 297px; //as per design
        }

        &-text {
          padding-left: 20px;
          padding-right: 20px;

          @include mq('large') {
            padding-left: var(--emu-common-spacing-none);
            padding-right: var(--emu-common-spacing-none);
          }
        }

        &-title {
          h3,
          h4,
          h5 {
            font-size: var(--emu-semantic-font-sizes-narrow-large);
            line-height: var(--emu-semantic-line-heights-narrow-large);

            @include mq('large') {
              font-size: var(--emu-semantic-font-sizes-wide-large);
              line-height: var(--emu-semantic-line-heights-wide-medium);
            }
          }
        }

        &-logo {
          margin-top: 20px;
          margin-bottom: 20px;

          @include mq('large') {
            height: 75px; // needed to make sure that the logo is aligned center in this
            display: flex;
            justify-content: left;
            align-items: center;
          }

          img {
            margin: auto;
            max-height: 75px;

            @include mq('large') {
              margin: var(--emu-common-spacing-none);
            }
          }
        }

        &-cta {
          margin-top: 32px;
        }

        > .button {
          margin-top: auto;
        }
      }
    }

    &__contact {
      padding-top: 44px;
      padding-left: 20px;
      padding-right: 20px;
      text-align: center;

      @include mq('large') {
        text-align: left;
        padding-top: 88px;
        padding-left: 81px;
        padding-right: 146px;
      }

      &-main {
        display: flex;
        flex-direction: column;

        @include mq('large') {
          flex-direction: row;
          gap: 90px;
        }

        > .container {
          flex: 1;
          @include mq('large') {
            width: calc(33.33% - 10px);
          }
        }
      }

      &-section {
        &-title {
          text-align: center;
          margin-top: 20px;
          margin-bottom: 9px;

          @include mq('large') {
            text-align: left;
            margin-top: 33px;
            margin-bottom: 17px;
          }

          h3,
          h4,
          h5 {
            font-size: var(--emu-semantic-font-sizes-narrow-large);
            line-height: var(--emu-common-line-heights-wide-medium);
            letter-spacing: -1px;

            @include mq('large') {
              line-height: var(--emu-semantic-line-heights-narrow-medium);
              font-size: var(--emu-semantic-font-sizes-wide-large);
            }
          }
        }

        &-text {
          margin-top: 9px;

          @include mq('large') {
            margin-top: 17px;
          }

          a {
            text-decoration: none;
            color: var(--emu-semantic-colors-primary-purple-200);
            text-wrap: nowrap;
          }

          p {
            br {
              display: none;

              @include mq('xx-large') {
                display: block;
              }
            }
          }
        }
      }

      &-title .cmp-title__text {
        text-align: center;

        @include mq('large') {
          text-align: left;
          line-height: normal;
        }
      }
    }
  }
}

.practice-support__steps-query-content {
  @include aem-editor-view {
    position: static !important;
    opacity: 1 !important;
  }
}
