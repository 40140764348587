#about {
  .content-flex-end {
    display: flex;
    justify-content: end;
  }

  .common-teaser {
    &__wrapper {
      .cmp-teaser__description {
        margin-left: var(--emu-common-spacing-none);
        margin-right: var(--emu-common-spacing-none);
      }

      .content-container {
        > .content {
          @include mq('large') {
            margin-bottom: 84px;
            padding-top: 164px;
          }
        }
      }

      @include mq('large') {
        .aaaem-teaser .cmp-teaser__title {
          line-height: normal;
          margin-bottom: 7px;
        }
      }
    }
  }
  .common-teaser__disclaimer {
    p {
      text-align: left;
      text-indent: -6px;
      letter-spacing: -0.2px;
    }
  }

  .about {
    // introduction with image grid
    &__intro {
      &-title {
        margin-top: 18px;

        @include mq('large') {
          margin-top: 25px;
        }
      }

      &-disclaimer {
        margin-top: 20px;

        @include mq('large') {
          margin-top: 33px;
        }

        p {
          br {
            display: none;

            @include mq('large') {
              display: block;
            }
          }
        }
      }

      &-grid {
        margin-top: 26px;
        margin-bottom: 42px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;

        @include mq('large') {
          margin-top: 20px;
          margin-bottom: 91px;
          justify-content: center;
          flex-direction: row;
          gap: 47px;
        }
      }

      &-grid-item {
        .cmp-text {
          margin-top: 18px;

          p {
            color: var(--emu-semantic-colors-secondary-dark-gray-100);
            font-weight: var(--emu-common-font-weight-regular);
          }
        }

        .cmp-title {
          h4 {
            color: var(--emu-semantic-colors-primary-purple-300);
            letter-spacing: -1px;

            @include mq('large') {
              font-size: var(--emu-semantic-font-sizes-wide-large);
              line-height: 30px;
            }
          }
        }

        .cmp-image {
          display: flex;
          justify-content: center;
        }

        img {
          border-radius: 50%;
        }
      }
    }

    // clinical study navigation box
    &__clinical-study {
      padding-top: 33px;
      padding-bottom: 40px;
      margin-left: 20px;
      margin-right: 20px;
      border-radius: var(--emu-common-border-radius-medium);
      background-color: #f8f7f8; // used only once

      @include mq('large') {
        padding-top: 68px;
        padding-bottom: 73px;
        margin-left: auto;
        margin-right: auto;
      }

      > .button {
        text-align: center;

        .about__clinical-study__cta {
          padding-top: 10px;
          padding-bottom: 10px;

          .cmp-button__text {
            line-height: normal;
          }
        }
      }

      &-title {
        margin-bottom: 24px;

        @include mq('large') {
          margin-bottom: 31px;

          .cmp-title__text {
            font-size: var(--emu-semantic-font-sizes-wide-large);
            line-height: var(--emu-semantic-line-heights-wide-medium);
          }
        }
      }
    }

    // features flippable carousel
    &__features {
      &-title {
        margin-top: 44px;
        margin-bottom: 5px;

        .cmp-title__text {
          text-align: center;

          @include mq('xx-large') {
            text-align: left;
          }
        }

        @include mq('large') {
          margin-top: 87px;
          margin-bottom: 19px;
        }
      }

      &-carousel {
        margin-bottom: 24px;

        @include mq('large') {
          margin-bottom: 63px;
        }
      }

      // values from figma
      .flippable-card__wrapper {
        width: 298px;
      }

      .flippable-card {
        &__side {
          padding-left: 30px;
          padding-right: 30px;
          padding-top: 30px;
          padding-bottom: 30px;
          min-height: 100%;

          .cmp-image {
            height: 30px;
          }

          .cmp-text {
            padding-top: 17px;
            font-weight: var(--emu-common-font-weight-regular);

            @include mq('large') {
              padding-top: 27px;
            }
          }
        }

        &__front {
          .front-card {
            height: 100%;

            > .cmp-container {
              height: 100%;
              display: flex;
              flex-direction: column;

              > .button {
                margin-top: auto;
              }
            }
          }

          .cmp-text {
            font-size: 30px;
            line-height: var(--emu-semantic-line-heights-wide-xl);
            font-weight: var(--emu-common-font-weight-regular);
            color: var(--emu-semantic-colors-primary-purple-300);
            letter-spacing: -1.5px;
          }

          .cmp-button {
            display: flex;
            align-items: center;
            padding-top: 10px;
            padding-right: 20px;
            padding-bottom: 10px;
            padding-left: 20px;

            img {
              margin-left: 10px;
            }
          }
        }
      }
    }

    // timeline carousel
    &__timeline {
      &-title {
        margin-bottom: 20px;

        @include mq('large') {
          margin-bottom: 65px;
        }

        .cmp-title__text {
          br {
            display: block;

            @include mq('large') {
              display: none;
            }
          }

          @include mq('large') {
            line-height: normal;
          }
        }
      }

      &-carousel {
        margin-bottom: 44px;

        // making sure that the user select is disabled for every element inside the carousel, so that the drag functionality is not effected
        * {
          user-select: none;
        }

        .aaaem-carousel__content {
          margin-left: var(--emu-common-spacing-none);
          margin-right: var(--emu-common-spacing-none);
        }

        .tns-inner {
          margin-left: 20px;
          // as per the design direction, the carousel should start where the menu starts, above 1440px
          @include mq('xx-large') {
            padding-left: calc(
              50vw - 720px + 80px - 20px
            ); // 80 is the additional spacing required for matching the header, 20px is the margin being added already for the tns-inner
          }

          // as per the design, the carousel should be aligned to the left by 55px
          // 20px being the margin, that is reduced from 55px
          @include mq('3000px') {
            padding-left: 35px;
          }
        }

        .tns-item {
          position: relative;

          &:not(:last-of-type) {
            .aaaem-carousel__item-container {
              width: 320px;
              padding-right: 30px;
            }

            // line above the carousel
            &::before {
              content: '';
              height: 1.5px;
              display: block;
              background: var(--emu-semantic-colors-secondary-dark-gray-100);
              position: absolute;
              top: 13px;
              width: 100%;
            }
          }

          &:nth-last-of-type(2) {
            .aaaem-carousel__item-container {
              width: 290px;
              padding-right: var(--emu-common-spacing-none);
            }
          }

          &:last-of-type {
            padding-left: 17px;
            padding-right: 30px;

            // counter balancing the space added to tns-inner at 1440px
            @include mq('xx-large') {
              padding-right: calc(50vw - 720px + 80px - 20px + 30px);
            }

            // counter balancing the space added to tns-inner at 1440px
            @include mq('3000px') {
              padding-right: 65px;
            }

            &::before {
              content: '';
              display: block;
              position: absolute;
              width: 100%;
              top: 13px;
              left: 0;
            }
          }
        }

        // year text
        &-year {
          position: relative;
          left: -13px;
          top: 17px;
          padding-top: var(--emu-common-spacing-xxs);

          p {
            font-size: 14px;
            line-height: 21px;
            font-weight: var(--emu-common-font-weight-light);
          }

          // dot above the year in timeline
          &::before {
            content: '';
            display: block;
            position: absolute;
            height: 13px;
            width: 13px;
            border-radius: 50%;
            background-color: var(--emu-semantic-colors-primary-purple-200);
            top: -10px;
            left: var(--emu-common-spacing-small);
          }
        }

        // beyond text
        &-beyond {
          background-color: var(--emu-common-colors-white);
          display: inline-block;

          p {
            font-size: 14px;
            line-height: 21px;
            font-weight: var(--emu-common-font-weight-light);
          }
        }

        // box with content and background
        &-item-content-box {
          padding-top: 23px;
          padding-left: 30px;
          padding-right: 30px;
          padding-bottom: 23px;
          border-radius: var(--emu-common-border-radius-medium);
          margin-bottom: var(--emu-common-border-radius-medium);
          margin-top: 25px;

          .cmp-title__text {
            line-height: normal;
            font-weight: var(--emu-common-font-weight-regular);
            color: var(--emu-common-colors-black);
          }

          p {
            padding-top: 9px;
            font-size: var(--emu-common-font-sizes-wide-medium);
            line-height: 21px;
            font-weight: var(--emu-common-font-weight-light);
          }

          .content-box__disclaimer {
            font-size: 14px;
          }
        }

        img {
          border-radius: var(--emu-common-border-radius-medium);
        }

        .alle-logo__container {
          background-color: var(--emu-common-colors-black);
          padding-top: 37px;
          padding-bottom: 38px;
          border-radius: var(--emu-common-border-radius-medium);

          .alle-logo {
            &--regular {
              img {
                max-height: 55px;
              }
            }
          }

          .cmp-image {
            display: flex;
            justify-content: center;

            img {
              border-radius: var(--emu-common-border-radius-none);
            }
          }
        }
      }
    }

    &__authenticity {
      &-title {
        margin-bottom: 21px;

        @include mq('large') {
          margin-bottom: 35px;
        }
      }

      &-text {
        margin-bottom: 33px;

        @include mq('large') {
          margin-bottom: 52px;
          max-width: 635px;
          margin-left: auto;
          margin-right: auto;
        }
      }

      &-subtitle {
        margin-bottom: 27px;

        @include mq('large') {
          margin-bottom: 43px;
        }

        .cmp-title__text {
          letter-spacing: -1.5px;
          color: var(--emu-semantic-colors-primary-purple-300);
          line-height: 28px;
          font-weight: var(--emu-common-font-weight-regular);

          @include mq('large') {
            font-size: 30px;
            line-height: var(--emu-common-line-heights-narrow-xxl);
          }
        }
      }

      .img-map {
        margin-bottom: 50px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        width: 335px;

        @include mq('large') {
          margin-bottom: 62px;
          width: 604px;
        }

        img {
          border-radius: var(--emu-common-border-radius-medium);
        }

        .emu-image-map {
          max-width: 100%;
          width: 335px;

          @include mq('large') {
            width: 604px;
          }
        }

        &__content {
          width: 218px; // as per figma

          @include mq('large') {
            width: 506px; // as per figma
          }
        }
      }

      &-warning-image {
        margin-bottom: 14px;
        display: flex;
        justify-content: center;

        @include mq('large') {
          display: none;
        }
      }

      &-subtitle-text {
        margin-bottom: 22px;

        @include mq('large') {
          margin-bottom: 9px;
        }

        .cmp-title__text {
          @include mq('large') {
            text-align: left;
          }
        }
      }

      &-disclaimer {
        margin-bottom: 22px;

        @include mq('large') {
          margin-bottom: 88px;
        }

        p {
          text-align: center;

          @include mq('large') {
            text-align: left;
          }
        }
      }
    }
  }
}
