@font-face {
  font-family: 'BrownStd';
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: url('./assets/fonts/Brown/BrownLLWeb-Light.woff2') format('woff2');
}

@font-face {
  font-family: 'BrownStd';
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url('./assets/fonts/Brown/BrownLLWeb-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'BrownStd';
  font-weight: 400;
  font-style: italic;
  font-display: swap;
  src: url('./assets/fonts/Brown/BrownLLWeb-Italic.woff2') format('woff2');
}

@font-face {
  font-family: 'BrownStd';
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url('./assets/fonts/Brown/BrownLLWeb-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'BrownStd';
  font-weight: 700;
  font-style: italic;
  font-display: swap;
  src: url('./assets/fonts/Brown/BrownLLWeb-BoldItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'BrownStd';
  font-weight: 300;
  font-style: italic;
  font-display: swap;
  src: url('./assets/fonts/Brown/BrownLLWeb-LightItalic.woff2') format('woff2');
}
