.ajaxContainer {
  .cmp-ajax-form-error {
    border: var(--emu-common-border-width-thin) solid
      var(--emu-common-colors-red-500);
    color: var(--emu-common-colors-red-500);
    display: none;
    margin-bottom: 30px;
    margin-top: var(--emu-common-spacing-none);
    padding: 10px;
  }

  .error-message,
  .recaptcha-required-message {
    margin-top: var(--emu-common-spacing-xxs);
    margin-bottom: var(--emu-common-spacing-none);
    color: var(--emu-common-colors-red-500);
    font-size: var(--emu-common-font-sizes-narrow-large);
  }

  .cmp-ajax-form-success {
    display: none;
  }

  .cmp-form-text {
    position: relative;
  }

  .form-content {
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    @include mq('large') {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    &::before,
    &::after {
      display: none;
    }
  }

  .form-control {
    height: 60px;
    width: 100%;
    background-color: var(--emu-common-colors-white);
    border-radius: var(--emu-semantic-border-radius-xs);
    border: var(--emu-common-border-width-thin) solid
      var(--emu-semantic-colors-secondary-dark-gray-300); //to match figma design
    padding-left: 9px;
    padding-right: 9px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  label {
    color: var(--emu-semantic-colors-secondary-dark-gray-200);
    font-size: var(--emu-common-font-sizes-narrow-medium);
    font-weight: var(--emu-common-font-weight-bold);
    line-height: var(--emu-semantic-line-heights-narrow-large);
    position: absolute;
    top: 0;
    left: 9px;
    @include mq('large') {
      font-size: 11.48px;
    }
  }

  // increasing specificity
  input.form-control {
    height: 66px; // to match the design
    color: var(--emu-semantic-colors-secondary-dark-gray-200);
    padding-top: 30px;

    @include mq('large') {
      font-size: var(--emu-semantic-font-sizes-wide-medium);
      line-height: var(--emu-semantic-line-heights-wide-large);
      padding-top: 33px;
      padding-right: 10px;
      padding-bottom: 11px;
      padding-left: 10px;
    }

    &::placeholder {
      color: var(--emu-semantic-colors-secondary-dark-gray-200);
    }
  }

  .button {
    text-align: center;
    button {
      color: var(--emu-common-colors-white);
      background-color: var(--emu-semantic-colors-primary-purple-200);
      border-radius: 40px;
      padding-left: var(--emu-common-spacing-large);
      padding-right: var(--emu-common-spacing-large);
      padding-top: var(--emu-common-spacing-medium);
      padding-bottom: var(--emu-common-spacing-medium);
      color: var(--emu-common-colors-white);
      line-height: var(--emu-semantic-line-heights-narrow-large);
      font-weight: var(--emu-common-font-weight-bold);
    }
  }

  &.js-response-errors {
    .cmp-ajax-form-error {
      display: block;
    }
  }

  &.js-response-success {
    .cmp-ajax-form-success {
      display: block;
    }

    .cmp-ajax-form-error,
    form {
      display: none;
    }
  }

  [data-component='recaptcha'] {
    .recaptcha-required-message {
      display: none;
    }
  }

  .emu-form.form-touched {
    [data-component='recaptcha'].has-error {
      .recaptcha-required-message {
        display: block;
      }
    }
  }
}
