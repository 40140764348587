.news {
  &__wrapper {
    position: relative;

    &--card-grid,
    &--dashboard-grid {
      padding-left: 20px;
      padding-right: 20px;

      @include mq('large') {
        padding-left: 80px;
        padding-right: 80px;
      }
    }
  }

  sup {
    font-size: 65%;
    line-height: 0;
    position: relative;
    vertical-align: super;
    font-weight: var(--emu-common-font-weight-light);

    @include mq('large') {
      font-size: 55%;
    }
  }

  p {
    margin: var(--emu-common-spacing-none);
    color: inherit;
  }

  &__main {
    &::before {
      content: '.';
      color: var(--emu-common-colors-transparent);
      display: none;
      width: 48px;
      height: 48px;
      border: 5px solid var(--emu-semantic-colors-secondary-dark-gray-100);
      border-bottom-color: var(--emu-common-colors-transparent);
      border-radius: 50%;
      box-sizing: border-box;
      animation: rotation 1s linear infinite;
      position: absolute;
      right: var(--emu-common-spacing-none);
      left: var(--emu-common-spacing-none);
      top: var(--emu-common-spacing-none);
      bottom: var(--emu-common-spacing-none);
      margin: auto;

      @include mq('large') {
        width: 100px;
        height: 100px;
      }

      @keyframes rotation {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }

    &--show-loader {
      &::before {
        display: block;
      }
    }
  }

  &__title {
    h2 {
      letter-spacing: -2px;

      @include mq('large') {
        letter-spacing: -3.5px;
      }

      p {
        display: inline;
      }
    }

    &-search-keyword,
    &-tag-keyword {
      display: inline;
      color: var(--emu-semantic-colors-primary-purple-200);
    }
  }

  &__article-poster-image {
    display: grid;

    img {
      width: 100%;
      border-radius: var(--emu-common-border-radius-xs);
    }
  }

  &__article-title {
    margin-top: 26px;

    @include mq('large') {
      margin-top: var(--emu-common-spacing-none);
    }

    h4 {
      letter-spacing: -0.5px;

      @include mq('large') {
        letter-spacing: -1px;
        font-size: var(--emu-semantic-font-sizes-wide-large);
        line-height: var(--emu-semantic-line-heights-wide-medium);
      }
    }

    a {
      text-decoration: none;
      color: inherit;
    }
  }

  &__top-section {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include mq('large') {
      justify-content: start;
      gap: 20px;
    }
  }

  &__cta {
    display: flex;
    align-items: start;

    @include mq('large') {
      align-items: center;
    }

    span {
      border: var(--emu-common-border-width-medium) solid
        var(--emu-semantic-colors-primary-purple-200);
      padding-top: var(--emu-common-spacing-xxs);
      padding-bottom: var(--emu-common-spacing-xxs);
      padding-left: var(--emu-common-spacing-small);
      padding-right: var(--emu-common-spacing-small);
      border-radius: 20px;
      font-size: var(--emu-common-font-sizes-wide-medium);
      line-height: normal;
      font-weight: var(--emu-common-font-weight-bold);

      @include mq('large') {
        padding-top: 6px;
        padding-bottom: 6px;
        padding-left: 14px;
        padding-right: 14px;
      }

      a {
        text-decoration: none;
        color: var(--emu-semantic-colors-primary-purple-200);
      }
    }
  }

  &__pagination {
    padding-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    @include mq('large') {
      padding-top: 51px;
    }

    ul {
      margin: var(--emu-common-spacing-none);
      padding-left: var(--emu-common-spacing-none);
      list-style-type: none;
      display: flex;
      justify-content: center;
    }
  }

  &__page {
    font-weight: var(--emu-common-font-weight-bold);
    padding-left: var(--emu-common-spacing-small);
    padding-right: var(--emu-common-spacing-small);
    cursor: pointer;

    a {
      text-decoration: none;
      color: var(--emu-semantic-colors-primary-purple-200);
    }

    &--active {
      a {
        color: var(--emu-semantic-colors-primary-purple-300);
      }
    }

    &--hidden {
      display: none;
    }

    &--dots {
      &::after {
        content: '...';
        padding-left: var(--emu-common-spacing-small);
      }
    }
  }

  &__pagination-button {
    background-image: url('../../assets/images/right-caret.png');
    width: 7px; // as per figma
    height: 13px; // as per figma
    background-repeat: no-repeat;

    &--hidden {
      visibility: hidden;
    }

    &--prev {
      transform: rotate(180deg);
    }
  }

  &__no-result-message {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: var(--emu-common-spacing-medium);

    @include mq('large') {
      padding-left: 80px;
      padding-right: 80px;
    }

    p {
      font-size: var(--emu-semantic-font-sizes-narrow-large);
      line-height: var(--emu-semantic-line-heights-narrow-large);
      font-weight: var(--emu-common-font-weight-regular);

      @include mq('large') {
        font-size: 32px;
        line-height: var(--emu-semantic-line-heights-wide-xl);
      }
    }
  }

  &__wrapper--dashboard-grid {
    padding-top: 81px;

    @include mq('large') {
      padding-top: 198px;
    }

    .news {
      &__top-section {
        margin-bottom: var(--emu-common-spacing-large);
      }

      &__title {
        h2 {
          text-align: center;

          @include mq('large') {
            text-align: left;
            font-size: var(--emu-semantic-font-sizes-wide-xxxl);
            line-height: var(--emu-semantic-line-heights-wide-xxxl);
          }
        }
      }

      &__main {
        gap: 30px;
        display: grid;

        @include mq('large') {
          display: grid;
          grid-template-columns: repeat(2, minmax(0, 1fr));
          grid-row-gap: 25px;
          grid-column-gap: 30px;
        }
      }

      &__article {
        &:first-child {
          @include mq('large') {
            grid-row-start: 1;
            grid-row-end: 4;

            .news__article-title {
              margin-top: 34px;
              margin-bottom: 23px;

              h4 {
                font-size: var(--emu-semantic-font-sizes-narrow-xxl);
                line-height: 40px;
              }
            }
          }
        }

        &:not(:first-child) {
          @include mq('large') {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            gap: 30px;
          }

          .news__article-poster-image,
          .news__article-content {
            @include mq('large') {
              flex: 1;
              width: 50%;
            }
          }

          .news__article-poster-image {
            @include mq('large') {
              width: 150px; // to make the image look good with the content
            }

            @include mq('x-large') {
              width: 297px; // to match the design.
            }

            img {
              @include mq('large') {
                width: 100%;
                height: auto;
                object-fit: contain;
                object-position: top;
              }
            }
          }

          .news__article-title {
            h4 {
              font-size: var(--emu-semantic-font-sizes-narrow-large);
              line-height: var(--emu-semantic-line-heights-narrow-large);
              word-break: break-word;

              @include mq('x-large') {
                font-size: 24px;
                line-height: 28px;
              }
            }
          }
        }
      }

      &__article-title {
        margin-bottom: 12px;

        @include mq('large') {
          margin-bottom: 20px;
        }

        h4 {
          @include mq('large') {
            line-height: var(--emu-semantic-font-sizes-wide-large);
          }
        }
      }
    }
  }

  &__wrapper--card-grid {
    padding-top: 44px;

    @include mq('large') {
      padding-top: 52px;
    }

    .news {
      &__top-section {
        margin-bottom: 17px;

        @include mq('large') {
          margin-bottom: 31px;
        }
      }

      &__title {
        h2 {
          @include mq('large') {
            font-size: var(--emu-semantic-font-sizes-wide-xxxl);
            line-height: var(--emu-semantic-line-heights-wide-xxxl);
          }
        }
      }

      &__main {
        display: grid;
        grid-row-gap: 30px;

        @include mq('large') {
          grid-template-columns: repeat(3, minmax(0, 1fr));
          grid-column-gap: 30px;
          grid-row-gap: 56px;
        }
      }

      &__article-title {
        margin-bottom: 3px;

        @include mq('large') {
          margin-top: 29px;
          margin-bottom: 19px;
        }
      }
    }
  }

  &__wrapper--list {
    padding-top: 40px;

    @include mq('large') {
      padding-left: var(--emu-common-spacing-none);
      padding-right: var(--emu-common-spacing-none);
      padding-top: var(--emu-common-spacing-none);
    }

    .news {
      &__top-section {
        margin-bottom: 17px;

        @include mq('large') {
          margin-bottom: 27px;
          justify-content: space-between;
          padding-top: 49px;
        }
      }

      &__title {
        h2 {
          @include mq('large') {
            font-size: var(--emu-semantic-font-sizes-wide-xl);
            line-height: normal;
            letter-spacing: -1px;
          }
        }
      }

      &__main {
        display: grid;
        grid-row-gap: 30px;

        @include mq('large') {
          grid-row-gap: 36px;
        }
      }

      &__article {
        @include mq('large') {
          display: flex;
          gap: 34px;
        }
      }

      &__article-poster-image {
        display: block;

        @include mq('large') {
          padding-top: var(--emu-common-spacing-xs);
        }

        img {
          @include mq('large') {
            width: 116px; // as per figma
          }
        }
      }

      &__article-content {
        @include mq('large') {
          max-width: 298px; // as per figma
        }

        @include mq('xx-large') {
          width: 298px;
        }

        .article-tags__item {
          @include mq('large') {
            font-size: 8px;
          }

          @include mq('xx-large') {
            font-size: 14px;
          }
        }
      }

      &__article-title {
        margin-bottom: var(--emu-common-spacing-xs);

        h4 {
          @include mq('large') {
            letter-spacing: -0.5px;
            font-size: var(--emu-common-font-sizes-wide-medium);
            line-height: var(--emu-common-line-heights-wide-large);
          }

          @include mq('xx-large') {
            font-size: var(--emu-semantic-font-sizes-narrow-large);
          }
        }
      }
    }
  }
}

.disable-pointer-events {
  pointer-events: none;
}
