[data-component='text'],
[data-component='title'] {
  color: var(--emu-common-colors-black);

  p,
  li {
    color: inherit;
    font-size: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    font-family: var(--emu-semantic-font-families-body);
    margin: var(--emu-common-spacing-none);
  }

  sup {
    font-size: 65%;
    line-height: 0;
    position: relative;
    vertical-align: super;
    font-weight: var(--emu-common-font-weight-light);
  }

  i {
    sup {
      font-size: 52%;
      font-style: normal;
      font-weight: var(--emu-common-font-weight-regular);
    }
  }

  b {
    font-weight: var(--emu-common-font-weight-bold);
  }

  .emphasis {
    display: inline-block;
  }
}

[data-component='text'] {
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: var(--emu-semantic-colors-primary-purple-300);
  }

  &.color--white {
    h1,
    h2,
    h3,
    h4,
    h5 {
      color: var(--emu-common-colors-white);
    }
  }
}

[data-component='title'] {
  font-weight: var(--emu-common-font-weight-bold);
  color: var(--emu-semantic-colors-primary-purple-300);
  h1,
  h2,
  h3,
  h4,
  h5 {
    letter-spacing: -1px;
  }

  sup {
    font-size: 55%;
  }
}

[data-component='text'] {
  font: var(--emu-semantic-typography-narrow-body-regular);
}
