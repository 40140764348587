.emu-button {
  .cmp-button__text {
    line-height: 0.5;
    display: block;

    @media (max-width: 374px) {
      line-height: 1;
    }
  }
}
