#results {
  .personalization {
    &--results {
      .ghost {
        display: none;
      }
      .results__intro {
        max-width: 1062px;
        margin-top: var(--emu-common-spacing-none);
      }
      .results-card__grid {
        flex-wrap: nowrap;
      }
      .results-card__item {
        img {
          width: 98%;
          @include mq('large') {
            width: 100%;
          }
        }
      }
      .results-card__disclaimer {
        margin-top: var(--emu-common-spacing-medium);
      }
      .results__data {
        padding-top: 38px;
        padding-bottom: 38px;
      }
      .partnering-benefits__title {
        padding-top: 38px;
        padding-bottom: var(--emu-common-spacing-none);
        text-align: center;
      }
      .botox-cards {
        @include mq('large') {
          padding-top: 50px;
          padding-bottom: 60px;
        }
      }
      .botox-order-wrapper {
        &__btn {
          font-size: var(--emu-common-font-sizes-narrow-xl);
          border-radius: 40px;
          padding-top: 30px; //for personalization
          padding-bottom: 30px; //for personalization
          .emu-button .cmp-button__text {
            line-height: 2;
          }
        }
        .container--inner {
          > .container:first-child {
            @include mq('large') {
              padding-top: var(--emu-common-spacing-none);
              padding-bottom: var(--emu-common-spacing-none);
            }
          }
        }
      }
    }
    .form-container {
      padding-left: 80px;
      padding-right: 80px;
      padding-top: var(--emu-common-spacing-small);
      padding-bottom: var(--emu-common-spacing-large);

      .form-content {
        > .text,
        > .recaptcha {
          @include mq('large') {
            width: calc(50% - 20px);
          }
        }

        .cmp-ajax-form-error,
        > .container {
          width: 100%;
        }
      }

      &__btn-wrapper {
        display: flex;
        flex-direction: column;
        gap: 20px;

        @include mq('large') {
          gap: 22px;
        }
      }

      &__bottom-text {
        > p {
          font-size: 14px;
          line-height: 21px;
          color: var(--emu-semantic-colors-primary-purple-400);
          text-indent: -5px;
        }
      }
    }
  }
}
