[data-component='modal'] {
  .modal-wrapper {
    padding-left: 20px;
    padding-right: 20px;
  }

  .modal-content {
    width: 100%; // as per figma
    padding-top: 43px;
    padding-bottom: 44px;
    padding-right: 20px;
    padding-left: 24px;
    border-radius: var(--emu-common-border-radius-medium);
    margin-top: 40px; // as per figma
    margin-bottom: var(--emu-common-spacing-none);

    @include mq('large') {
      padding-top: 47px;
      padding-right: 60px;
      padding-left: 70px;
      margin-top: 121px; // as per figma
    }

    .cmp-title__text {
      margin-bottom: 17px;

      @include mq('large') {
        margin-bottom: 23px;
        font-size: var(--emu-semantic-font-sizes-wide-large);
        line-height: var(--emu-semantic-line-heights-wide-medium);
      }
    }

    ul {
      padding-left: var(--emu-common-spacing-medium);
      margin-top: var(--emu-common-spacing-none);
      margin-bottom: 10px;

      @include mq('large') {
        margin-bottom: 17px;
      }

      li {
        margin-bottom: 10px;

        @include mq('large') {
          margin-bottom: var(--emu-common-spacing-medium);
        }
      }
    }

    .references {
      margin-top: 10px;
      margin-bottom: var(--emu-common-sizing-none);
      padding: var(--emu-common-sizing-none);

      @include mq('large') {
        margin-top: var(--emu-common-spacing-medium);
      }
    }

    .close {
      width: 20px;
      height: 20px;
      background-image: url('../../assets/images/close.png');
      background-size: cover;
      right: 20px;
      top: 18px;

      @include mq('large') {
        top: 20px;
      }

      &,
      &:hover {
        opacity: 1;
      }

      span {
        display: none;
      }
    }
  }

  &.botox-day-modal .modal-wrapper {
    z-index: 800;
    padding-left: 0;
    padding-right: 0;
  }

  &.platysma-popup .modal-wrapper {
    padding-left: 0;
    padding-right: 0;
    z-index: 800;
  }

  &.pat-loyalty {
    &__modal {
      .modal-wrapper {
        padding-left: 27.5px;
        padding-right: 27.5px;

        .modal-content {
          padding: var(--emu-common-spacing-none);
          border-radius: var(--emu-common-border-radius-none);
          margin-top: 120px;
          background-color: #f1f1f1; // no variable for this. Used only once
          max-width: 320px;

          @include mq('large') {
            max-width: 620px; //to match the design
            margin-top: 120px;
            margin-bottom: 80px;
          }

          .main-close {
            display: none;
          }
        }
      }

      .pat-loyalty {
        &__bg {
          position: absolute;
          top: 0;
          width: 224px; // needed to match the design
          right: 0;

          @include mq('large') {
            width: 433px; //to match the design
            right: -1px;
          }
        }

        &__inner {
          position: relative;
          z-index: var(--emu-common-other-z-index-layer);
          padding-top: 14px;
          padding-left: 20px;
          padding-right: 20px;
          padding-bottom: 30px;

          @include mq('large') {
            padding-top: 28px;
            padding-left: 30px;
            padding-right: 30px;
          }
        }

        &__logo {
          margin-left: -6px; // to match the design
          margin-bottom: 47px;

          @include mq('large') {
            margin-bottom: 91px;
            margin-left: -3px;
          }

          svg {
            width: 94px; //as per figma
            height: 45px; // needed to match the design

            @include mq('large') {
              width: 183px; //as per figma
              height: 88px; // needed to match the design
            }
          }
        }

        &__title {
          margin-left: -4px; // needed to match the design

          @include mq('large') {
            padding-left: var(--emu-common-spacing-xs);
          }

          .cmp-title__text {
            font-family: var(--emu-common-font-families-serif);
            font-size: 25.765px; // as per figma
            font-weight: var(--emu-common-font-weight-regular);
            line-height: 28.857px; // as per figma
            letter-spacing: 0.5px; // to match the design
            margin-bottom: 24px;
            color: var(--emu-common-colors-black);

            @include mq('large') {
              font-size: 50px;
              line-height: var(--emu-semantic-line-heights-wide-xxxl);
              letter-spacing: -1px;
              margin-bottom: 51px;
            }
          }
        }

        &__text {
          padding-top: 14px;
          margin-bottom: 23px;
          border-top: var(--emu-common-border-width-thin) solid
            var(--emu-common-colors-black);

          @include mq('large') {
            padding-left: 30px;
            padding-top: 40px;
            margin-bottom: 38px;
            padding-right: 30px;
          }

          p {
            font-size: 16px;
            font-weight: var(--emu-common-font-weight-regular);

            @include mq('large') {
              font-size: 21px;
              line-height: var(--emu-semantic-line-heights-wide-medium);
              letter-spacing: 0.1px;
            }
          }
        }

        &__cta {
          width: 100%;
          border-radius: var(--emu-common-border-radius-none);
          padding-top: 14px;
          padding-right: 38px;
          padding-bottom: 13px;
          padding-left: 38px;
          justify-content: center;
          display: flex;
          color: var(--emu-semantic-colors-primary-purple-400);
          background-color: #9898fc; // no variable present for this
          margin-bottom: 19px;
          text-decoration: none;

          @include mq('large') {
            font-size: 24px;
            padding-top: 21px;
            padding-bottom: 21px;
            margin-bottom: 29px;
          }
        }

        &__disc {
          font-size: 10px;
          line-height: normal;
          letter-spacing: -0.1px;
          color: var(--emu-semantic-colors-primary-purple-400);
          font-weight: var(--emu-common-font-weight-regular);

          @include mq('large') {
            font-size: 11px;
          }

          a {
            color: var(--emu-semantic-colors-primary-purple-400);
          }
        }
      }
    }
  }
}

body.js-modal-opened {
  overflow: hidden;

  [data-component='container-isi-v2'].isi-scrollable .isi-container {
    z-index: var(--emu-common-other-z-index-modal);
  }
}

.aaaem-modal {
  &.botox-day-modal {
    .modal-content {
      padding: var(--emu-common-spacing-none);
      max-width: 621px; // needed to match the design
      width: 100%;
      background: transparent;
      top: 20px;
      margin-top: var(--emu-common-spacing-none);

      @include mq('medium') {
        top: 40px;
      }
      @include mq('large') {
        padding: var(--emu-common-spacing-none);
      }
    }

    .modal-image-desktop {
      display: none;

      @include mq('medium') {
        display: block;
      }
    }

    .modal-image-mobile {
      @include mq('medium') {
        display: none;
      }
    }

    .main-close {
      display: none;
    }

    .modal-content .container--modal-details {
      padding: var(--emu-common-spacing-none);
      margin-left: 27px;
      margin-right: 27px;
      background: var(--emu-common-colors-white);

      @include mq('medium') {
        margin-left: 0;
        margin-right: 0;
      }

      .aaaem-text {
        max-width: 100%;
      }

      .image .cmp-image__image {
        width: 100%;
      }

      .button {
        text-align: center;
      }

      .pop {
        &-title {
          color: var(--emu-common-colors-black);
          font-size: 24px;
          text-align: center;
          line-height: 30px;
          font-weight: var(--emu-common-font-weight-regular);
          font-family: var(--emu-semantic-font-families-extra-brown-std);
          margin-left: 20px;
          margin-right: 20px;
          text-transform: uppercase;

          @include mq('medium') {
            font-size: 30px;
            line-height: 36px;
            margin-left: 0;
            margin-right: 0;
          }
        }

        &-text {
          color: var(--emu-common-colors-black);
          font-family: var(--emu-semantic-font-families-extra-brown-std);
          font-size: 16px;
          line-height: 20px;
          font-weight: var(--emu-common-font-weight-regular);
          text-align: center;
          padding-top: 20px;
          padding-bottom: 30px;

          @include mq('medium') {
            font-size: 24px;
            line-height: 28px;
            padding-top: 16px;
          }
        }

        &-button {
          padding-top: 12px;
          padding-bottom: 12px;
          padding-left: 58px;
          padding-right: 58px;
          text-align: center;
          line-height: 29px;
          font-family: var(--emu-semantic-font-families-extra-brown-std);
          font-weight: var(--emu-common-font-weight-regular);
          color: var(--emu-common-colors-black);
          font-size: 24px;
          text-transform: uppercase;
          margin: var(--emu-common-spacing-none);
          border-radius: 40px;
          border-width: 2px;
          border-color: var(--emu-common-colors-black);

          @include mq('medium') {
            font-size: 24px;
            line-height: 29px;
          }
        }

        &-disclaimer {
          font-family: var(--emu-semantic-font-families-extra-brown-std);
          font-size: 8px;
          font-weight: var(--emu-common-font-weight-regular);
          line-height: 9.68px;
          text-align: center;
          margin-top: 20px;

          @include mq('medium') {
            margin-top: 40px;
          }

          sup {
            top: -1px;
          }

          a {
            color: var(--emu-common-colors-black);
          }
        }
      }

      .container--modal-wrapper {
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    .modal-cta-container div[data-ref='close-cta'] {
      display: none;
    }

    .modal-cta-container div[data-ref='confirm-cta'] {
      display: none;
    }
  }

  &.platysma-popup {
    .modal-content {
      max-width: 621px; // needed to match the design
      width: 100%;
      background: transparent;
      top: 120px;
      padding-bottom: var(--emu-common-spacing-none);
      margin-left: auto;
      margin-right: auto;
      padding: 0;
      margin-top: 0;
    }

    .popup-image-desktop {
      display: none;

      @include mq('medium') {
        display: block;
      }

      img {
        width: 100%;
      }
    }

    .popup-image-mobile {
      @include mq('medium') {
        display: none;
      }

      img {
        width: 100%;
      }
    }

    .main-close {
      display: none;
    }

    .platysma-container {
      margin-left: 27px;
      margin-right: 27px;

      @include mq('medium') {
        margin-left: 0;
        margin-right: 0;
      }

      .modal-container {
        background: #2e1844;

        .text-container {
          padding: 20px;

          @include mq('medium') {
            padding-top: 12px;
            padding-bottom: 12px;
            padding-left: 60px;
            padding-right: 60px;
          }

          p {
            color: var(--emu-common-colors-white);
            text-align: center;
            font-family: var(--emu-semantic-font-families-body);
            font-size: 15px;
            font-weight: 300;
            line-height: 18px;
            letter-spacing: -0.3px;
          }

          .title {
            font-size: 14px;
            text-transform: uppercase;
          }

          h2 {
            color: var(--emu-common-colors-white);
            text-align: center;
            font-family: var(--emu-common-font-families-serif);
            font-size: 48px;
            font-weight: 400;
            line-height: 48px;
            letter-spacing: -0.96px;
            padding-top: 8px;
          }

          h3 {
            color: var(--emu-common-colors-white);
            text-align: center;
            font-family: var(--emu-common-font-families-serif);
            font-size: 24px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: -0.48px;
            padding-bottom: 8px;
            padding-top: 5px;
          }

          h4 {
            color: var(--emu-common-colors-white);
            text-align: center;
            font-family: var(--emu-semantic-font-families-body);
            font-size: 15px;
            font-weight: var(--emu-semantic-font-weight-bold);
            line-height: 18px;

            sup {
              font-size: 50%;
              top: 0;
            }

            @include mq('medium') {
              br {
                display: none;
              }
            }
          }

          .small {
            font-size: 11px;
            font-weight: 400;
            margin-left: 20px;
            margin-right: 20px;
            line-height: 13px;
            letter-spacing: normal;
            padding-top: 8px;

            a {
              color: var(--emu-common-colors-white);
            }

            sup {
              font-size: 75%;
              top: 0;
            }
          }
        }

        .link-container {
          display: flex;
          padding-left: 30px;
          padding-right: 30px;
          padding-top: 10px;
          padding-bottom: 10px;
          background: var(--emu-semantic-colors-primary-purple-100);
          position: relative;
          justify-content: center;

          @include mq('medium') {
            padding-top: 13px;
            padding-bottom: 13px;
          }

          .link-text {
            color: #2e1844;
            text-align: center;
            font-size: 16px;
            font-weight: var(--emu-semantic-font-weight-bold);
            text-transform: uppercase;
            line-height: 16px;

            @include mq('medium') {
              font-size: 18px;
            }

            p {
              font-family: var(--emu-semantic-font-families-body);
            }

            a {
              text-decoration: none;
              color: #2e1844;
            }

            svg {
              width: 7px;
            }
          }
        }
      }
    }
  }
}
