#search {
  .button {
    .article-details__button--back-button {
      padding-left: 20px;

      @include mq('large') {
        padding-left: 80px;
      }
    }
  }
}
