#education {
  .education {
    &__download-cta {
      background-color: var(--emu-semantic-colors-primary-purple-100);
      border-radius: var(--emu-common-border-radius-xs);
      padding-top: var(--emu-common-spacing-none);
      padding-right: 13px;
      padding-bottom: var(--emu-common-spacing-none);
      padding-left: 13px;
      align-items: center;
      gap: 14px;
      height: 40px;

      &::after {
        content: '';
        width: var(--emu-common-sizing-small); // needed to match the design
        height: 21px; // needed to match the design
        background-image: url('./assets/images/page.png');
        background-size: cover;
      }

      span {
        font-size: var(--emu-semantic-font-sizes-narrow-medium);
        font-weight: var(--emu-common-font-weight-regular);
      }
    }

    &__resource-text {
      text-align: center;

      @include mq('large') {
        max-width: 625px; // as per figma
        text-align: left;
      }
    }

    &__how-botox-works {
      margin-top: 26px;
      margin-bottom: 42px;

      @include mq('large') {
        margin-top: 50px;
        margin-bottom: 85px;
        display: flex;
        gap: 30px;
        justify-content: center;
      }

      > .container:first-child {
        width: 100%;
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;

        @include mq('large') {
          width: auto;
          min-width: 406px; // to make sure flex does not take effect
          margin-top: var(--emu-common-spacing-small);
        }
      }

      > .container:last-child {
        @include mq('large') {
          flex: 1;
        }
      }

      &-embed {
        .plyr {
          border-radius: var(--emu-common-border-radius-small);

          @include mq('large') {
            border-radius: var(--emu-common-border-radius-medium);
          }
        }
      }

      &-content-container {
        > .button {
          text-align: center;

          @include mq('large') {
            text-align: left;
          }
        }
      }

      &-title {
        margin-top: var(--emu-common-spacing-none);

        .cmp-title__text {
          color: var(--emu-common-colors-black);
          line-height: 36px;
          text-align: center;

          @include mq('large') {
            text-align: left;
            line-height: normal;
          }

          @include mq('x-large') {
            margin-right: -40px; // to get the title to single line as per the design
          }
        }
      }

      &-text {
        margin-top: 20px;
      }

      &-cta {
        margin-top: 19px;

        @include mq('large') {
          margin-top: 29px;
        }
      }
    }

    &__other {
      @include mq('large') {
        margin-bottom: 29px;
        display: flex;
        flex-direction: column;
      }

      &-title {
        margin-bottom: 22px;

        @include mq('large') {
          margin-bottom: 37px;
        }

        .cmp-title__text {
          color: var(--emu-semantic-colors-primary-purple-300);
          letter-spacing: -1px;

          @include mq('large') {
            font-size: var(--emu-semantic-font-sizes-wide-xl);
            line-height: normal;
            text-align: left;
          }
        }
      }

      &-content {
        margin-bottom: 50px;

        @include mq('large') {
          margin-bottom: 41px;
          display: flex;
          gap: 30px;
        }
      }

      &-embed {
        width: 189px; // as per figma
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 18px;

        @include mq('large') {
          margin-bottom: var(--emu-common-spacing-none);
        }

        .plyr--video {
          border-radius: var(--emu-common-border-radius-xs);
        }
      }

      &-content-container {
        > .button {
          text-align: center;

          @include mq('large') {
            text-align: left;
          }
        }

        @include mq('large') {
          max-width: 625px; // as per figma
        }
      }

      &-sub-title {
        margin-bottom: 12px;

        .cmp-title__text {
          line-height: 28px;
          letter-spacing: -1px;
          color: var(--emu-semantic-colors-primary-purple-300);
          font-weight: var(--emu-common-font-weight-regular);

          @include mq('large') {
            text-align: left;
          }
        }
      }

      &-text {
        margin-bottom: 20px;

        @include mq('large') {
          margin-bottom: 29px;
        }
      }
    }

    &__tool {
      padding-top: var(--emu-common-spacing-large);
      padding-bottom: 50px;
      padding-left: 20px;
      padding-right: 20px;

      @include mq('large') {
        padding-top: 60px;
        padding-bottom: 27px;
        padding-left: var(--emu-common-spacing-none);
        padding-right: var(--emu-common-spacing-none);
        max-width: 1062px; // as per figma
        margin-left: auto;
        margin-right: auto;
        display: flex;
        gap: 87px;
      }

      &-image {
        display: flex;
        justify-content: center;

        @include mq('large') {
          justify-content: left;
        }

        img {
          @include mq('large') {
            width: 351px;
          }
        }
      }

      &-content {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include mq('large') {
          align-items: start;
          margin-top: 35px;
        }
      }

      &-title {
        margin-bottom: 11px;

        @include mq('large') {
          margin-bottom: 23px;
        }

        .cmp-title__text {
          line-height: var(--emu-semantic-line-heights-narrow-medium);

          @include mq('large') {
            font-size: var(--emu-semantic-font-sizes-wide-large);
          }
        }
      }

      &-text {
        margin-bottom: 17px;

        @include mq('large') {
          max-width: 515px; // as per figma
          margin-bottom: 28px;
        }

        p {
          text-align: center;

          @include mq('large') {
            text-align: left;
            font-size: var(--emu-semantic-font-sizes-narrow-large);
            line-height: var(--emu-semantic-line-heights-wide-medium);
          }
        }
      }

      &-cta {
        padding-top: 12px;
        padding-bottom: 11px;
        padding-left: 14px;
        padding-right: 14px;
      }
    }

    &__ami {
      background-color: var(--emu-semantic-colors-secondary-dark-gray-400);
      display: flex;
      flex-direction: column;

      @include mq('large') {
        flex-direction: row;
        justify-content: center;
      }

      &-teaser {
        background-color: #ca9a8e; // used once

        @include mq('large') {
          width: 408px; // as per figma
        }

        .disclaimer {
          position: absolute;
          bottom: 13px;
          right: 18px;

          @include mq('large') {
            bottom: var(--emu-common-spacing-medium);
          }

          p {
            margin: var(--emu-common-spacing-none);
            font-size: 14px;
            line-height: normal;
          }
        }
      }

      &-wrapper {
        padding-left: 20px;
        padding-right: 20px;

        @include mq('large') {
          padding-top: 40px;
          padding-bottom: 24px;
          padding-left: 40px;
          padding-right: 40px;
        }

        @include mq('xx-large') {
          padding-top: 81px;
          padding-bottom: 72px;
          padding-left: 79px;
          padding-right: 80px;
        }
      }

      &-content-wrapper {
        @include mq('large') {
          display: flex;
          gap: 40px;
          margin-bottom: var(--emu-common-spacing-medium);
        }

        @include mq('x-large') {
          gap: 80px;
          margin-bottom: 69px;
        }
      }

      &-content {
        @include mq('large') {
          max-width: 435px; // as per figma
        }

        > .button {
          text-align: center;

          @include mq('large') {
            text-align: left;
          }
        }
      }

      &-image {
        display: flex;
        justify-content: center;
        margin-bottom: 22px;
        margin-top: 36px;

        @include mq('large') {
          justify-content: left;
          margin-bottom: 20px;
          margin-top: 15px;
        }
      }

      &-title {
        margin-bottom: 18px;

        @include mq('large') {
          margin-bottom: 34px;
        }

        .cmp-title__text {
          text-align: center;

          @include mq('large') {
            text-align: left;
            font-size: var(--emu-semantic-font-sizes-wide-xl);
            line-height: normal;
          }
        }
      }

      &-text {
        margin-bottom: 29px;

        @include mq('large') {
          margin-bottom: 33px;
        }

        p {
          font-size: var(--emu-semantic-font-sizes-narrow-large);
          line-height: var(--emu-semantic-line-heights-narrow-medium);
          text-align: center;

          @include mq('large') {
            text-align: left;
          }
        }
      }

      &-cta {
        margin-bottom: 34px;
        padding-top: var(--emu-common-spacing-medium);
        padding-bottom: var(--emu-common-spacing-medium);
        padding-right: 42px;
        padding-left: 43px;

        @include mq('large') {
          margin-bottom: var(--emu-common-spacing-none);
        }
      }

      &-carousel {
        @include mq('large') {
          max-width: 358px; // as per figma
        }

        &-title {
          margin-bottom: 19px;

          @include mq('large') {
            margin-bottom: 23px;
            max-width: 255px; // to match figma
          }

          .cmp-title__text {
            font-size: var(--emu-semantic-font-sizes-wide-large);
            line-height: var(--emu-semantic-line-heights-narrow-medium);
          }
        }

        &-text {
          margin-bottom: 21px;

          @include mq('large') {
            margin-bottom: var(--emu-common-spacing-none);
          }

          p {
            font-size: var(--emu-semantic-font-sizes-narrow-large);
            line-height: var(--emu-semantic-line-heights-narrow-medium);
          }
        }
      }

      &-disclaimer {
        margin-bottom: 44px;

        @include mq('large') {
          margin-bottom: var(--emu-common-spacing-none);
        }

        p {
          font-size: 14px;
          line-height: 21px;
          color: var(--emu-semantic-colors-primary-purple-400);
          text-indent: -5px;
        }
      }
    }
  }

  .areas-hotspot {
    &__sub-title .emphasis {
      display: block;

      @include mq('large') {
        display: inline-block;
      }
    }
    @include mq('large') {
      padding-bottom: 34px;
    }
  }

  .aaaem-modal .modal-wrapper.is-open {
    background-color: var(--emu-common-colors-transparent);
    max-height: calc(100vh - 250px);
    padding-bottom: 55px;
    overflow-y: auto;

    @include mq('medium') {
      max-height: calc(100vh - 200px);
    }

    &::after {
      content: '';
      display: block;
      width: 100vw;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      background-color: var(--emu-semantic-colors-surface-modal-backdrop);
      z-index: var(--emu-common-other-z-index-behind);
    }
  }
}
