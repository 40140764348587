.aaaem-accordion {
  padding-bottom: var(--emu-common-spacing-none);

  &__header {
    border-radius: 12px;
    border: var(--emu-common-border-width-thin) solid
      var(--emu-component-lists-accordion-item-color-border-bottom-closed-light);
    -webkit-tap-highlight-color: var(--emu-common-colors-transparent);
    margin-bottom: 30px;
    transition: all linear
      var(--emu-component-lists-accordion-item-body-transition);

    &.-ba-is-active {
      margin-bottom: var(--emu-common-spacing-none);
      background-color: var(--emu-semantic-colors-primary-purple-300);

      .aaaem-accordion__title {
        color: var(--emu-common-colors-white);
      }

      .aaaem-accordion__icon:after,
      .aaaem-accordion__icon:before {
        background-color: var(--emu-common-colors-white);
      }
    }
  }

  &__button {
    -webkit-tap-highlight-color: var(--emu-common-colors-transparent);
  }

  &__title {
    font-size: 21px;
    font-weight: var(--emu-common-font-weight-regular);
    line-height: 1.33;
    letter-spacing: -1px;
    display: flex;
    align-items: center;
    gap: 12px;

    &::before {
      content: '';
      background-image: url('../../assets/images/question.png');
      width: 20px;
      height: 20px;
    }
  }
}
