div.header {
  .header--sticky {
    background: linear-gradient(
      180deg,
      var(--emu-common-colors-white) 50%,
      rgba(255, 255, 255, 0) 100%
    );

    @include mq('large') {
      background-color: var(--emu-common-colors-white);
      border-bottom: var(--emu-common-border-width-medium) solid
        var(--emu-semantic-colors-primary-purple-300);
    }

    .header {
      &__menu-mobile {
        > .button {
          .aaaem-button span {
            background: var(--emu-common-colors-black);
          }
        }

        > div.container {
          .header__menu-mobile-dropdown {
            @include mq('large') {
              margin-top: 36px;
              align-items: center;
              padding-top: 9px;
              padding-bottom: 10px;
              padding-left: 80px;
            }

            .emu-navigation__content-wrapper,
            > div.button {
              @include mq('large') {
                margin-top: var(--emu-common-spacing-none);
              }
            }
          }
        }

        &-logo-light,
        &-logo {
          display: none;
        }

        &-dropdown {
          > div.container {
            // to match the design.
            &:first-child {
              @include mq('large') {
                display: none;
              }
            }
          }

          &-top-content {
            .header__menu-mobile-logo {
              display: block; // to match the design.

              @include mq('large') {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
