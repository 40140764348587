.article-details {
  @include mq('large') {
    margin-right: 40px;
  }

  @include mq('x-large') {
    margin-right: 99px;
  }

  img {
    width: 100%;
    display: flex;
    border-radius: var(--emu-common-border-radius-xs);
  }

  p {
    margin: var(--emu-common-spacing-none);
    color: inherit;
  }

  sup {
    font-size: 65%;
    line-height: 0;
    position: relative;
    vertical-align: super;
    font-weight: var(--emu-common-font-weight-light);

    @include mq('large') {
      font-size: 55%;
    }
  }

  // back to top cta
  &__back-to-top {
    @include mq('large') {
      width: 100%;
    }

    a {
      position: absolute;
      top: 102px;
      left: var(--emu-common-spacing-none);
      align-items: center;
      z-index: var(--emu-common-other-z-index-base);
      text-decoration: none;
      color: var(--emu-semantic-colors-primary-purple-200);
      font-size: var(--emu-common-font-sizes-wide-medium);
      font-weight: var(--emu-common-font-weight-regular);
      line-height: normal;
      flex-direction: row-reverse;
      right: auto;

      @include mq('large') {
        top: unset;
        left: unset;
        right: var(--emu-common-spacing-none);
      }

      svg,
      img {
        height: auto;
        width: auto;
        padding-left: 13px;
      }
    }
  }

  &__title {
    margin-bottom: 9px;
    color: var(--emu-semantic-colors-primary-purple-300);
    letter-spacing: -2px;

    @include mq('large') {
      font-size: 44px;
      line-height: normal;
      letter-spacing: -3.5px;
      margin-bottom: 23px;
    }

    @include mq('x-large') {
      font-size: var(--emu-semantic-font-sizes-wide-xxxl);
      line-height: var(--emu-semantic-line-heights-wide-xxxl);
    }
  }

  &__poster-image {
    margin-bottom: 26px;

    @include mq('large') {
      margin-bottom: 47px;
    }
  }

  &__content {
    margin-bottom: 21px;

    @include mq('large') {
      margin-bottom: 39px;
    }

    &.aaaem-text p {
      margin-bottom: 13px;

      @include mq('large') {
        margin-bottom: 15px;
      }
    }

    &-wrapper {
      padding-bottom: 2px;

      @include mq('large') {
        padding-bottom: 18px;
      }

      .aaaem-text p,
      .aaaem-text h1,
      .aaaem-text h2,
      .aaaem-text h3,
      .aaaem-text h4,
      .aaaem-text h5,
      .aaaem-text h6,
      .aaaem-text ul,
      .image,
      .cmp-title__text,
      .embed {
        margin-bottom: 19px;

        a {
          color: var(--emu-semantic-colors-primary-purple-200);
          font-weight: var(--emu-common-font-weight-bold);
          text-decoration: none;
        }
      }

      h1 {
        font-size: 40px;
        line-height: normal;
      }

      .aaaem-text h2 {
        font-size: var(--emu-semantic-font-sizes-narrow-large);
        line-height: var(--emu-semantic-line-heights-narrow-medium);
        letter-spacing: -1px;
        margin-top: 30px;
        margin-bottom: 17px;

        @include mq('large') {
          font-size: var(--emu-semantic-font-sizes-wide-large);
          margin-bottom: 20px;
        }
      }

      .aaaem-text h3 {
        font-size: var(--emu-semantic-font-sizes-narrow-large);
        line-height: 28px;
        letter-spacing: -1px;
        font-weight: var(--emu-common-font-weight-regular);
        margin-top: 24px;
        margin-bottom: 17px;
      }
    }

    &-disclaimer {
      margin-top: 39px;

      @include mq('large') {
        margin-top: 85px;
      }

      &.aaaem-text p {
        font-size: 14px;
        line-height: 21px;
        font-weight: var(--emu-common-font-weight-light);
        text-indent: -7px;
      }
    }
  }

  &__footer-tag-container {
    position: relative;

    .article-tags__wrapper {
      @include mq('large') {
        max-width: 70%;
      }

      @include mq('x-large') {
        max-width: 60%;
      }
    }
  }

  &__tag-and-share-wrapper {
    margin-bottom: 30px;

    @include mq('large') {
      margin-bottom: 52px;
    }
  }

  &__share-container {
    margin-top: 18px;

    @include mq('large') {
      margin-top: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    p {
      color: var(--emu-semantic-colors-primary-purple-400);
    }
  }

  &__share-container--footer {
    margin-top: 20px;
    margin-bottom: 40px;

    @include mq('large') {
      margin-top: 13px;
      margin-bottom: var(--emu-common-spacing-none);
    }
  }

  &__with-date-back-to-top {
    display: block;
  }

  &__without-date-back-to-top {
    display: none;
  }

  &__share-top,
  &__share-bottom {
    margin-top: 6px;

    @include mq('large') {
      margin-top: var(--emu-common-spacing-none);
    }
  }
}

.hide-article-poster .article-details .article-details__poster-image {
  display: none;
}
