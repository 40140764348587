[data-component='container-isi-v2'].isi-scrollable .isi-container {
  z-index: var(--emu-component-containers-isi-z-index);
}

[data-component='container-isi-v2'] {
  .aaaem-isi {
    &-banner {
      background-color: var(--emu-semantic-colors-primary-purple-300);
      opacity: 0.8;

      @include mq('large') {
        padding-top: var(--emu-common-spacing-small);
        padding-right: 80px;
        padding-left: 80px;
        padding-bottom: var(--emu-common-spacing-small);
      }

      @include mq('xxx-large') {
        padding-left: 60px;
        padding-right: 60px;
      }
    }

    &-container {
      border: none;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      background-color: var(--emu-common-colors-transparent);

      sup {
        font-size: 9px;
        vertical-align: revert; // to match the design.
        position: relative;
        top: 0;
      }

      &.isi-container {
        .aaaem-isi-container__isi-content {
          background: rgba(255, 255, 255, 0.8);
          height: 209px;

          // media query needed to match the design given at https://www.figma.com/file/q4LFrpOpwkTNjyqwNbdkpR/025774-HCP-Website-Revamp-Phase-II-(04-19-2024)?type=design&node-id=4002-11724&mode=dev
          @include mq('414px') {
            height: 182px;
          }

          @include mq('large') {
            height: 134px;
          }

          @include mq('xxx-large') {
            height: 110px;
          }

          > div {
            padding-top: var(--emu-common-spacing-small);
            padding-bottom: var(--emu-common-spacing-small);

            @include mq('large') {
              padding-top: 27px;
              padding-bottom: 27px;
            }
          }

          p,
          ul {
            font-size: var(--emu-semantic-font-sizes-narrow-medium);
            line-height: var(--emu-common-line-heights-wide-medium);
            margin-bottom: var(--emu-common-spacing-small);

            @include mq('large') {
              margin-bottom: 21px;
            }
          }

          .hcp-botox-isi {
            &__box-content {
              padding-top: var(--emu-common-spacing-small);
              margin-bottom: var(--emu-common-spacing-small);

              @include mq('large') {
                padding-top: 16px;
                padding-right: 21px;
                padding-left: 21px;
              }

              p {
                margin: var(--emu-common-spacing-none);
              }
            }

            &__links {
              p {
                margin-bottom: var(--emu-common-spacing-none);
              }
            }
          }

          &.js-expand {
            height: var(--emu-component-containers-isi-open-content-size);

            @include mq('large') {
              height: max(31vh, 278px); // to match the design.
            }
          }
        }
      }

      &__banner-content {
        display: block !important; // to override the css display property being added via JS

        p {
          font-size: var(--emu-semantic-font-sizes-narrow-medium);
          line-height: var(--emu-common-line-heights-wide-large);
          margin: var(--emu-common-spacing-none);
          color: var(--emu-common-colors-white);
          font-weight: var(--emu-common-font-weight-regular);
        }
      }

      &__button-wrapper {
        position: relative;
        top: -9px; // to match the design.
        right: -14px;

        @include mq('large') {
          top: unset;
          right: unset;
          align-self: center;
        }

        button.plus-minus-icon {
          padding: var(--emu-common-spacing-none);

          &:active {
            outline: none;
          }

          .aaaem-button__icon {
            margin: var(--emu-common-spacing-none);

            &::before,
            &::after {
              background-color: var(--emu-common-colors-white);
            }
          }
        }
      }

      &__isi-content {
        background: rgba(255, 255, 255, 0.8);

        > div:first-child {
          @include mq('large') {
            padding-top: 16px;
            padding-right: 80px;
            padding-bottom: 30px;
            padding-left: 80px;
          }

          @include mq('xxx-large') {
            padding-right: 60px;
            padding-left: 60px;
          }
        }

        .aaaem-text {
          color: var(--emu-semantic-colors-primary-purple-300);
          font-weight: var(--emu-semantic-font-weight-light);

          @include mq('large') {
            font-size: 14px;
            line-height: 21px;
          }

          p {
            margin-bottom: 24px;
            font-size: var(--emu-semantic-font-sizes-narrow-medium);
            line-height: var(--emu-common-line-heights-wide-large);

            @include mq('large') {
              margin-bottom: 21px;
            }
          }
        }

        .hcp-botox-isi {
          &__box-content {
            border: 1px solid var(--emu-common-colors-black);
            margin-right: -10px;
            margin-bottom: 12px;
            margin-left: -10px;
            padding-top: 24px;
            padding-right: 10px;
            padding-left: 10px;
            text-align: justify;

            @include mq('large') {
              padding-top: 15px;
              padding-right: 24px;
              padding-left: 19px;
              margin-top: -7px;
              margin-right: -24px;
              margin-bottom: 15px;
              margin-left: -19px;
            }
          }

          &__list,
          &__links {
            p {
              margin-bottom: var(--emu-common-spacing-none);
            }
          }

          &__list {
            margin-bottom: 24px;

            @include mq('large') {
              margin-bottom: 21px;
            }

            p:not(:first-child) {
              margin-left: 14px;
              text-indent: -13px;
            }
          }

          &__links {
            a {
              color: inherit;
              overflow-wrap: break-word;
            }
          }
        }
      }
    }
  }
}

.top-text {
  padding-left: 34px;
  padding-right: 34px;
  margin-top: 20px;

  @include mq('large') {
    padding-left: 77px;
    padding-right: 77px;
    margin-top: var(--emu-common-spacing-none);
  }
}
