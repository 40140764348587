// custom styles for swiper
.swiper-carousel {
  .swiper-wrapper {
    // editor view styles
    @include aem-editor-view {
      display: block !important;
    }

    .swiper-slide {
      width: auto;
      overflow: hidden;
    }
  }

  &__outer-wrapper {
    position: relative;

    &--reached-end,
    &--disabled {
      .swiper-carousel__more-btn.aaaem-button {
        display: none;
      }
    }

    &--disabled {
      .swiper-wrapper {
        @include mq('large') {
          justify-content: center;

          > .container {
            margin-right: 22px !important; // needed to override any styles added via JS by swiperJS

            &:last-child {
              margin-right: var(
                --emu-common-spacing-none
              ) !important; // needed to override any styles added via JS by swiperJS
            }
          }
        }
      }
    }
  }

  &__more-btn.aaaem-button {
    z-index: var(--emu-common-other-z-index-layer);
    background-color: var(--emu-common-colors-transparent);
    bottom: -28px;
    right: 30px;
    position: absolute;
    font-weight: var(--emu-common-font-weight-bold);

    &:hover,
    &:focus,
    &:active {
      background-color: var(--emu-common-colors-transparent);
    }

    &::before {
      content: '';
      backdrop-filter: blur(10px);
      background-color: rgba(255, 255, 255, 0.2); //to match figma
      border-width: var(--emu-common-border-width-thin);
      border-style: solid;
      border-color: rgba(255, 255, 255, 0.3); //to match figma
      height: 36px; //to match figma
      width: 36px; //to match figma
      display: block;
      border-radius: 50%;
      left: 82px; //to match figma
      position: absolute;
      top: 3px; //to match figma
    }

    &::after {
      content: '';
      z-index: var(--emu-common-other-z-index-behind);
      background: radial-gradient(100% 47% at 100%, #9577c0 0%, #9577bf00 100%);
      width: 180px;
      height: 300px;
      display: block;
      position: absolute;
      top: -148px;
      right: -30px;
      pointer-events: none;
    }

    &:active {
      outline: none;
    }

    span.cmp-button__text {
      &::after,
      &::before {
        content: '';
        border-top: 0.15em solid var(--emu-common-colors-white);
        border-right: 0.15em solid var(--emu-common-colors-white);
        width: 12px; //to match figma
        height: 12px; //to match figma
        margin-left: 63px; //to match figma
        display: block;
        position: absolute;
        transform: rotate(45deg);
        top: 15px; //to match figma
      }

      &::before {
        margin-top: var(--emu-common-spacing-none);
        animation: 0.7s linear infinite arrowBefore;
      }

      &::after {
        margin-top: var(--emu-common-spacing-none);
        animation: 0.7s linear infinite arrowAfter;
      }

      @keyframes arrowBefore {
        0% {
          margin-left: 60px;
          opacity: 0.3;
        }
        100% {
          margin-left: 63px;
          opacity: 1;
        }
      }

      @keyframes arrowAfter {
        0% {
          margin-left: 64px;
          opacity: 1;
        }
        100% {
          margin-left: 68px;
          opacity: 0.3;
        }
      }
    }
  }

  &.swiper-carousel--disabled-in-mobile {
    .swiper-carousel__more-btn {
      @include mq('1023px', 'max-width') {
        display: none;
      }
    }

    .swiper-wrapper {
      @include mq('1023px', 'max-width') {
        gap: 22px;
        display: grid;
        transform: none !important;
      }

      > .container {
        @include mq('1023px', 'max-width') {
          margin-right: var(--emu-common-sizing-none) !important;
        }
      }
    }
  }
}
