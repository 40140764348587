.error-page {
  padding-top: 100px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: var(--emu-common-spacing-xl);

  @include mq('large') {
    padding-top: 200px;
    padding-left: 80px;
    padding-right: 80px;
  }

  .cmp-title {
    margin-bottom: var(--emu-common-spacing-large);
  }
}
