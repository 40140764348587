.share {
  ul {
    list-style-type: none;
    margin: var(--emu-common-spacing-none);
    padding-left: var(--emu-common-spacing-none);
    display: flex;

    li:not(:last-child) {
      margin-right: var(--emu-common-spacing-medium);
    }
  }

  &__media {
    display: flex;
    flex-direction: column;
    justify-content: center;

    img {
      max-width: 24px; // as per figma.
      width: 100%;
      height: 24px;
      object-fit: contain;
    }
  }
}
