.aaaem-teaser {
  .teaser-image-container {
    .cmp-teaser {
      &__image--mobile {
        display: block;

        @include mq('large') {
          display: none;
        }

        img {
          width: 100%;
        }
      }

      &__image--desktop {
        display: none;

        @include mq('large') {
          display: block;
        }

        img {
          width: 100%;
        }
      }
    }
  }
}
