
/**
 * ** 🚨 DO NOT EDIT THIS FILE DIRECTLY!
 * If you want to make edits make them in each appropriate token JSON file.
 */

$emu-semantic-colors-none: rgba(0,0,0,0) !default;
$emu-semantic-colors-surface-light: #FFFFFF !default;
$emu-semantic-colors-surface-dark: #000000 !default;
$emu-semantic-colors-surface-modal-backdrop: rgba(0, 0, 0, 0.4) !default;
$emu-semantic-colors-actions-primary-light: #FF1D15 !default;
$emu-semantic-colors-actions-primary-dark: #CC0700 !default;
$emu-semantic-colors-actions-on-primary-light: #000000 !default;
$emu-semantic-colors-actions-on-primary-dark: #FFFFFF !default;
$emu-semantic-colors-text-light: #000000 !default;
$emu-semantic-colors-text-dark: #FFFFFF !default;
$emu-semantic-colors-foreground-light: #000000 !default;
$emu-semantic-colors-foreground-dark: #FFFFFF !default;
$emu-semantic-colors-primary-purple-100: #D9D8E9 !default;
$emu-semantic-colors-primary-purple-200: #9577BF !default;
$emu-semantic-colors-primary-purple-300: #2E1844 !default;
$emu-semantic-colors-primary-purple-400: #160C1F !default;
$emu-semantic-colors-primary-purple-500: rgba(46, 24, 68, 0.9) !default;
$emu-semantic-colors-primary-purple-600: rgba(46, 24, 68, 0.5) !default;
$emu-semantic-colors-secondary-light-gray-100: #F4F4F4 !default;
$emu-semantic-colors-secondary-light-gray-200: #DAD9DB !default;
$emu-semantic-colors-secondary-dark-gray-100: #838187 !default;
$emu-semantic-colors-secondary-dark-gray-200: #414042 !default;
$emu-semantic-colors-secondary-dark-gray-300: rgba(131, 129, 135, 0.3) !default;
$emu-semantic-colors-secondary-dark-gray-400: rgba(131, 129, 135, 0.06) !default;
$emu-semantic-colors-secondary-gold-100: #F1EDE9 !default;
$emu-semantic-colors-secondary-gold-200: #E8E3DD !default;
$emu-semantic-colors-secondary-gold-300: #A08A5F !default;
$emu-semantic-font-weight-bold: 700 !default;
$emu-semantic-font-weight-light: 300 !default;
$emu-semantic-font-weight-black: 900 !default;
$emu-semantic-font-weight-regular: 300 !default;
$emu-semantic-font-families-body: 'BrownStd', sans-serif !default;
$emu-semantic-font-families-heading: 'BrownStd', sans-serif !default;
$emu-semantic-font-families-mono: 'BrownStd', sans-serif !default;
$emu-semantic-spacing-none: 0px !default;
$emu-semantic-spacing-horizontal: 8px !default;
$emu-semantic-spacing-vertical: 8px !default;
$emu-semantic-spacing-one-line-height-wide: 30px !default;
$emu-semantic-spacing-one-line-height-narrow: 30px !default;
$emu-semantic-font-sizes-narrow-medium: 18px !default;
$emu-semantic-font-sizes-narrow-large: 21px !default;
$emu-semantic-font-sizes-narrow-xl: 24px !default;
$emu-semantic-font-sizes-narrow-xxl: 32px !default;
$emu-semantic-font-sizes-narrow-xxxl: 32px !default;
$emu-semantic-font-sizes-wide-medium: 18px !default;
$emu-semantic-font-sizes-wide-large: 28px !default;
$emu-semantic-font-sizes-wide-xl: 36px !default;
$emu-semantic-font-sizes-wide-xxl: 48px !default;
$emu-semantic-font-sizes-wide-xxxl: 54px !default;
$emu-semantic-line-heights-narrow-large: 24px !default;
$emu-semantic-line-heights-narrow-medium: 30px !default;
$emu-semantic-line-heights-narrow-xl: 32px !default;
$emu-semantic-line-heights-narrow-xxl: 36px !default;
$emu-semantic-line-heights-narrow-xxxl: 36px !default;
$emu-semantic-line-heights-wide-large: 24px !default;
$emu-semantic-line-heights-wide-medium: 30px !default;
$emu-semantic-line-heights-wide-xl: 36px !default;
$emu-semantic-line-heights-wide-xxl: 54px !default;
$emu-semantic-line-heights-wide-xxxl: 56px !default;
$emu-semantic-border-radius-xs: 4px !default;
$emu-semantic-border-radius-small: 8px !default;
$emu-semantic-border-radius-medium: 16px !default;
$emu-semantic-border-radius-large: 32px !default;
$emu-semantic-border-radius-none: 0px !default;
$emu-semantic-border-width-thin: 1px !default;
$emu-semantic-border-width-medium: 2px !default;
$emu-semantic-border-width-thick: 4px !default;
$emu-semantic-border-width-none: 0px !default;
$emu-semantic-outline-width-thin: thin !default;
$emu-semantic-outline-width-medium: medium !default;
$emu-semantic-outline-width-thick: thick !default;
$emu-semantic-outline-width-unset: unset !default;
$emu-semantic-sizing-none: 0px !default;
$emu-semantic-sizing-xxs: 1px !default;
$emu-semantic-sizing-xs: 8px !default;
$emu-semantic-sizing-small: 16px !default;
$emu-semantic-sizing-medium: 32px !default;
$emu-semantic-sizing-large: 64px !default;
$emu-semantic-sizing-xl: 128px !default;
$emu-semantic-sizing-xxl: 256px !default;
$emu-semantic-sizing-breakpoints-small: 320px !default;
$emu-semantic-sizing-breakpoints-medium: 768px !default;
$emu-semantic-sizing-breakpoints-tablet: 992px !default;
$emu-semantic-sizing-breakpoints-large: 1024px !default;
$emu-semantic-sizing-breakpoints-x-large: 1200px !default;
$emu-semantic-sizing-breakpoints-xx-large: 1440px !default;
$emu-semantic-sizing-breakpoints-xxx-large: 1920px !default;
$emu-semantic-sizing-one-line-height-wide: 30px !default;
$emu-semantic-sizing-one-line-height-narrow: 30px !default;
$emu-semantic-typography-narrow-headings-xxxl: 700 32px/36px 'BrownStd', sans-serif !default;
$emu-semantic-typography-narrow-headings-xxl: 700 32px/36px 'BrownStd', sans-serif !default;
$emu-semantic-typography-narrow-headings-xl: 700 24px/32px 'BrownStd', sans-serif !default;
$emu-semantic-typography-narrow-headings-large: 700 21px/24px 'BrownStd', sans-serif !default;
$emu-semantic-typography-narrow-headings-medium: 700 18px/30px 'BrownStd', sans-serif !default;
$emu-semantic-typography-narrow-body-regular: 300 18/24 'BrownStd', sans-serif !default;
$emu-semantic-typography-wide-headings-xxxl: 700 54px/56px 'BrownStd', sans-serif !default;
$emu-semantic-typography-wide-headings-xxl: 700 48px/54px 'BrownStd', sans-serif !default;
$emu-semantic-typography-wide-headings-xl: 700 36px/36px 'BrownStd', sans-serif !default;
$emu-semantic-typography-wide-headings-large: 700 28px/24px 'BrownStd', sans-serif !default;
$emu-semantic-typography-wide-headings-medium: 700 18px/30px 'BrownStd', sans-serif !default;
$emu-semantic-typography-wide-body-regular: 300 18/24 'BrownStd', sans-serif !default;

$semantic: (
  'semantic': (
    'colors': (
      'none': $emu-semantic-colors-none,
      'surface': (
        'light': $emu-semantic-colors-surface-light,
        'dark': $emu-semantic-colors-surface-dark,
        'modal-backdrop': $emu-semantic-colors-surface-modal-backdrop
      ),
      'actions': (
        'primary': (
          'light': $emu-semantic-colors-actions-primary-light,
          'dark': $emu-semantic-colors-actions-primary-dark
        ),
        'on-primary': (
          'light': $emu-semantic-colors-actions-on-primary-light,
          'dark': $emu-semantic-colors-actions-on-primary-dark
        )
      ),
      'text': (
        'light': $emu-semantic-colors-text-light,
        'dark': $emu-semantic-colors-text-dark
      ),
      'foreground': (
        'light': $emu-semantic-colors-foreground-light,
        'dark': $emu-semantic-colors-foreground-dark
      ),
      'primary': (
        'purple': (
          '100': $emu-semantic-colors-primary-purple-100,
          '200': $emu-semantic-colors-primary-purple-200,
          '300': $emu-semantic-colors-primary-purple-300,
          '400': $emu-semantic-colors-primary-purple-400,
          '500': $emu-semantic-colors-primary-purple-500,
          '600': $emu-semantic-colors-primary-purple-600
        )
      ),
      'secondary': (
        'lightGray': (
          '100': $emu-semantic-colors-secondary-light-gray-100,
          '200': $emu-semantic-colors-secondary-light-gray-200
        ),
        'darkGray': (
          '100': $emu-semantic-colors-secondary-dark-gray-100,
          '200': $emu-semantic-colors-secondary-dark-gray-200,
          '300': $emu-semantic-colors-secondary-dark-gray-300,
          '400': $emu-semantic-colors-secondary-dark-gray-400
        ),
        'gold': (
          '100': $emu-semantic-colors-secondary-gold-100,
          '200': $emu-semantic-colors-secondary-gold-200,
          '300': $emu-semantic-colors-secondary-gold-300
        )
      )
    ),
    'fontWeight': (
      'bold': $emu-semantic-font-weight-bold,
      'light': $emu-semantic-font-weight-light,
      'black': $emu-semantic-font-weight-black,
      'regular': $emu-semantic-font-weight-regular
    ),
    'fontFamilies': (
      'body': $emu-semantic-font-families-body,
      'heading': $emu-semantic-font-families-heading,
      'mono': $emu-semantic-font-families-mono
    ),
    'spacing': (
      'none': $emu-semantic-spacing-none,
      'horizontal': $emu-semantic-spacing-horizontal,
      'vertical': $emu-semantic-spacing-vertical,
      'oneLineHeight': (
        'wide': $emu-semantic-spacing-one-line-height-wide,
        'narrow': $emu-semantic-spacing-one-line-height-narrow
      )
    ),
    'fontSizes': (
      'narrow': (
        'medium': $emu-semantic-font-sizes-narrow-medium,
        'large': $emu-semantic-font-sizes-narrow-large,
        'xl': $emu-semantic-font-sizes-narrow-xl,
        'xxl': $emu-semantic-font-sizes-narrow-xxl,
        'xxxl': $emu-semantic-font-sizes-narrow-xxxl
      ),
      'wide': (
        'medium': $emu-semantic-font-sizes-wide-medium,
        'large': $emu-semantic-font-sizes-wide-large,
        'xl': $emu-semantic-font-sizes-wide-xl,
        'xxl': $emu-semantic-font-sizes-wide-xxl,
        'xxxl': $emu-semantic-font-sizes-wide-xxxl
      )
    ),
    'lineHeights': (
      'narrow': (
        'large': $emu-semantic-line-heights-narrow-large,
        'medium': $emu-semantic-line-heights-narrow-medium,
        'xl': $emu-semantic-line-heights-narrow-xl,
        'xxl': $emu-semantic-line-heights-narrow-xxl,
        'xxxl': $emu-semantic-line-heights-narrow-xxxl
      ),
      'wide': (
        'large': $emu-semantic-line-heights-wide-large,
        'medium': $emu-semantic-line-heights-wide-medium,
        'xl': $emu-semantic-line-heights-wide-xl,
        'xxl': $emu-semantic-line-heights-wide-xxl,
        'xxxl': $emu-semantic-line-heights-wide-xxxl
      )
    ),
    'borderRadius': (
      'xs': $emu-semantic-border-radius-xs,
      'small': $emu-semantic-border-radius-small,
      'medium': $emu-semantic-border-radius-medium,
      'large': $emu-semantic-border-radius-large,
      'none': $emu-semantic-border-radius-none
    ),
    'borderWidth': (
      'thin': $emu-semantic-border-width-thin,
      'medium': $emu-semantic-border-width-medium,
      'thick': $emu-semantic-border-width-thick,
      'none': $emu-semantic-border-width-none
    ),
    'outlineWidth': (
      'thin': $emu-semantic-outline-width-thin,
      'medium': $emu-semantic-outline-width-medium,
      'thick': $emu-semantic-outline-width-thick,
      'unset': $emu-semantic-outline-width-unset
    ),
    'sizing': (
      'none': $emu-semantic-sizing-none,
      'xxs': $emu-semantic-sizing-xxs,
      'xs': $emu-semantic-sizing-xs,
      'small': $emu-semantic-sizing-small,
      'medium': $emu-semantic-sizing-medium,
      'large': $emu-semantic-sizing-large,
      'xl': $emu-semantic-sizing-xl,
      'xxl': $emu-semantic-sizing-xxl,
      'breakpoints': (
        'small': $emu-semantic-sizing-breakpoints-small,
        'medium': $emu-semantic-sizing-breakpoints-medium,
        'tablet': $emu-semantic-sizing-breakpoints-tablet,
        'large': $emu-semantic-sizing-breakpoints-large,
        'x-large': $emu-semantic-sizing-breakpoints-x-large,
        'xx-large': $emu-semantic-sizing-breakpoints-xx-large,
        'xxx-large': $emu-semantic-sizing-breakpoints-xxx-large
      ),
      'oneLineHeight': (
        'wide': $emu-semantic-sizing-one-line-height-wide,
        'narrow': $emu-semantic-sizing-one-line-height-narrow
      )
    ),
    'typography': (
      'narrow': (
        'headings': (
          'xxxl': $emu-semantic-typography-narrow-headings-xxxl,
          'xxl': $emu-semantic-typography-narrow-headings-xxl,
          'xl': $emu-semantic-typography-narrow-headings-xl,
          'large': $emu-semantic-typography-narrow-headings-large,
          'medium': $emu-semantic-typography-narrow-headings-medium
        ),
        'body': (
          'regular': $emu-semantic-typography-narrow-body-regular
        )
      ),
      'wide': (
        'headings': (
          'xxxl': $emu-semantic-typography-wide-headings-xxxl,
          'xxl': $emu-semantic-typography-wide-headings-xxl,
          'xl': $emu-semantic-typography-wide-headings-xl,
          'large': $emu-semantic-typography-wide-headings-large,
          'medium': $emu-semantic-typography-wide-headings-medium
        ),
        'body': (
          'regular': $emu-semantic-typography-wide-body-regular
        )
      )
    )
  )
);
