/** Added style for global footer **/
.footer {
  &__copyright-text {
    margin-top: var(--emu-common-spacing-large);
    margin-bottom: var(--emu-common-spacing-large);

    @include mq('large') {
      margin-top: var(--emu-common-spacing-none);
      margin-bottom: 11px;
      padding-bottom: 3px;
    }

    a {
      &,
      &:focus,
      &:active,
      &:hover {
        color: inherit;
        text-decoration: none;
      }
    }
  }

  &__links {
    ul {
      list-style: none;
      padding-left: var(--emu-common-spacing-none);
      margin: var(--emu-common-spacing-none);

      > li {
        &:not(:last-child) {
          margin-bottom: var(--emu-common-spacing-small);

          @include mq('large') {
            margin-bottom: var(--emu-common-spacing-none);
          }
        }

        a {
          display: inline-flex;
          gap: 11px;
          align-items: center;
        }

        img {
          max-width: 30px;
        }
      }

      @include mq('large') {
        display: flex;
        flex-direction: row;
        column-gap: 36px;
        flex-wrap: wrap;
        line-height: var(--emu-semantic-line-heights-narrow-xl);

        li {
          margin-bottom: var(--emu-common-spacing-none);
        }
      }
    }
  }

  &__wrapper-container {
    background-color: var(--emu-semantic-colors-primary-purple-100);

    .container--inner {
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 40px;
      padding-bottom: 34px;

      @include mq('large') {
        padding-top: 30px;
        padding-bottom: 46px;
        padding-left: 72px;
        padding-right: 72px;
        display: flex;
        column-gap: 62px;
        align-items: center;
        margin: auto;
        max-width: var(--emu-semantic-sizing-breakpoints-xx-large);
      }
    }

    .text {
      p,
      a,
      ul,
      li {
        font-size: 18px;
        font-weight: var(--emu-common-font-weight-light);
        line-height: 24px;
        color: var(--emu-semantic-colors-primary-purple-300);
        text-decoration: none;
        letter-spacing: -0.5px;

        @include mq('large') {
          letter-spacing: normal;
        }
      }

      li {
        a {
          @include mq('large') {
            line-height: var(--emu-semantic-line-heights-narrow-xl);
          }
        }
      }
    }
  }
}

// results page should show a different MLRC# Number in footer.
// Added another text component with new MLRC#.
// Made it hidden for all pages by default and when there is a .personalization--results class, then showing a diff element
.footer__copyright-text--results-personalization {
  display: none;

  @include aem-editor-view {
    display: block !important;
  }
}

// results page should show a different MLRC Number in footer.
// Added another text component with new MLRC.
// Made it hidden for all pages by default and when there is a .personalization--results class, then showing a diff element
#results:has(.personalization--results) {
  .footer__copyright-text {
    display: none;

    &--results-personalization {
      display: block;
    }
  }
}
