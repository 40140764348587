#home {
  .home {
    &__main {
      background: linear-gradient(
        180deg,
        var(--emu-semantic-colors-secondary-gold-200) 0%,
        var(--emu-semantic-colors-primary-purple-200) 40%
      );
      padding-top: 74px;

      @include mq('large') {
        padding-top: 200px;
        background: linear-gradient(
          180deg,
          var(--emu-semantic-colors-secondary-gold-200) 0%,
          var(--emu-semantic-colors-primary-purple-200) 100%
        );
      }
    }

    &__banner {
      &-inner {
        @include mq('large') {
          display: flex;
          padding-left: 20px;
          padding-right: 20px;
          max-width: var(--emu-semantic-sizing-breakpoints-xx-large);
          margin-left: auto;
          margin-right: auto;
        }

        // media query needed to match the design
        @include mq('1280px') {
          padding-left: 80px;
          padding-right: 80px;
        }

        > .container:has(.home__banner-img-container) {
          @include mq('large') {
            width: 100px;
            flex: 1;
          }
        }

        > .container:has(.home__banner-content-container) {
          @include mq('large') {
            margin-left: auto;
          }
        }
      }

      &-intro {
        padding-left: 20px;
        padding-right: 20px;
        text-align: center;
        max-width: 560px;
        margin-left: auto;
        margin-right: auto;

        @include mq('large') {
          text-align: left;
          padding-left: var(--emu-common-spacing-none);
          padding-right: var(--emu-common-spacing-none);
          margin-left: var(--emu-common-spacing-none);
          margin-right: var(--emu-common-spacing-none);
          max-width: 360px;
        }

        @include mq('xx-large') {
          max-width: 560px;
        }

        &-title {
          margin-bottom: 23px;

          h1,
          h2,
          h3 {
            font-size: 38px;
            line-height: 42px;
            color: var(--emu-semantic-colors-primary-purple-400);

            @include mq('xx-large') {
              font-size: 72px;
              line-height: 80px;
              letter-spacing: -5px;
            }
          }

          sup {
            top: 5px;
            left: -2px;
          }
        }
      }

      &-img {
        position: relative;

        &--mobile {
          // media query needed as mobile image is not looking good beyond 500px
          @include mq('500px') {
            display: none;
          }
        }

        &--desktop {
          display: none;

          // media query needed as mobile image is not looking good beyond 500px
          @include mq('500px') {
            display: block;
          }
        }

        img {
          width: 100%;
          // media query needed as mobile image is not looking good beyond 500px
          @include mq('500px') {
            width: auto;
          }

          @include mq('large') {
            position: absolute;
            left: 50%;
            top: 0;
            max-width: none;
            width: 600px;
            transform: translateX(-50%) translateY(-85px);
          }

          @include mq('xx-large') {
            width: 903px;
          }
        }
      }

      &-content {
        &-container {
          text-align: center;
          padding-left: 20px;
          padding-right: 20px;
          margin-top: -44px;
          margin-bottom: 36px;
          max-width: 560px;
          margin-left: auto;
          margin-right: auto;

          @include mq('large') {
            text-align: left;
            margin-top: var(--emu-common-spacing-none);
            padding-left: var(--emu-common-spacing-none);
            padding-right: var(--emu-common-spacing-none);
            width: 294px; // from design
            margin-left: var(--emu-common-spacing-none);
            margin-right: var(--emu-common-spacing-none);
            margin-bottom: var(--emu-common-spacing-none);
          }

          @include mq('xx-large') {
            margin-top: 57px;
          }
        }

        &-title {
          margin-bottom: 9px;

          @include mq('large') {
            margin-bottom: 22px;
          }

          h2,
          h3,
          h4 {
            color: var(--emu-common-colors-black);
            font-size: 26px;
            line-height: 30px;
            letter-spacing: normal;

            @include mq('xx-large') {
              font-size: 38px;
              line-height: 42px;
            }
          }
        }

        &-cta {
          margin-bottom: 13px;
          position: relative;

          @include mq('large') {
            margin-bottom: 18px;
          }

          a {
            color: var(--emu-common-colors-black);
            line-height: 42px;
            font-weight: var(--emu-common-font-weight-regular);
          }
        }

        &-text {
          font-size: 14px;
          line-height: 18px;
          letter-spacing: -0.2px;

          .emphasis {
            @include mq('large') {
              display: inline-block;
            }
          }
        }
      }
    }

    &__carousel {
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 22px;
      max-width: 500px;
      margin-left: auto;
      margin-right: auto;

      @include mq('large') {
        padding-bottom: 100px;
        max-width: none;
        padding-left: var(--emu-common-spacing-none);
        padding-right: var(--emu-common-spacing-none);
        padding-top: 39px;
      }

      // media query needed to match the design
      @include mq('1280px') {
        padding-top: 25px;
      }

      @include mq('xx-large') {
        padding-top: 46px;
      }

      .swiper-wrapper {
        > .container:has(.carousel-card--bottom-align) {
          @include mq('large') {
            margin-top: auto;
          }
        }

        > .container:has(.hcp-btx__img-text-card-wrapper) {
          @include mq('large') {
            margin-bottom: 85px;
          }
        }

        > .container:has(.carousel-card--stagger-align) {
          @include mq('large') {
            margin-top: 40px;
          }
        }
      }
    }
  }

  // card styles
  .hcp-btx {
    &__card {
      border-radius: var(--emu-common-border-radius-large);
      overflow: hidden;
      position: relative;

      .aaaem-title {
        .cmp-title__text {
          font-size: var(--emu-semantic-font-sizes-narrow-large);
          line-height: 28px;
          font-weight: var(--emu-common-font-weight-bold);
          letter-spacing: normal;
        }
      }

      .aaaem-text {
        font-size: var(--emu-common-font-sizes-wide-medium);
        line-height: normal;
      }

      &-link {
        position: static;
        display: inline-block;
        font-weight: var(--emu-common-font-weight-regular);

        &::after {
          content: '';
          background-image: url('./assets/images/arrow-right.png');
          position: absolute;
          display: inline-block;
          width: 12px;
          height: 9px;
          top: 6px;
          right: -16px;
        }

        a {
          color: inherit;
          text-decoration: none;

          &::before {
            content: '';
            position: absolute;
            display: inline-block;
            width: 100%;
            height: 100%;
            top: var(--emu-common-spacing-none);
            left: var(--emu-common-spacing-none);
          }

          &::after {
            content: '';
            background-image: url('./assets/images/arrow-right.png');
            position: relative;
            display: inline-block;
            width: 12px;
            height: 9px;
            top: var(--emu-common-spacing-none);
            right: -6px;
          }
        }
      }
    }

    &__text-card {
      &-upper {
        background: linear-gradient(
          80deg,
          var(--emu-semantic-colors-secondary-gold-100) 0%,
          var(--emu-semantic-colors-primary-purple-100) 100%
        ); // As per the design.
        padding-top: 25px;
        padding-right: 28px;
        padding-bottom: 26px;
        padding-left: var(--emu-common-spacing-large);
        margin-bottom: 22px;

        @include mq('large') {
          padding-right: 29px;
          width: 330px; // to match the design.
        }

        .cmp-title__text {
          margin-bottom: 14px;
          color: var(--emu-semantic-colors-primary-purple-300);
        }

        .aaaem-text {
          color: var(--emu-semantic-colors-primary-purple-200);
        }
      }

      &-bottom {
        padding-top: 27px;
        padding-right: 30px; // intentional to make sure that the content
        padding-bottom: 28px;
        padding-left: var(--emu-common-spacing-large);
        border: var(--emu-common-border-width-thin) solid
          var(--emu-common-colors-white);

        @include mq('large') {
          padding-top: 35px;
          padding-right: 13px;
          padding-bottom: 34px;
          width: 301px; // to match the design.
          margin-left: auto;
        }

        .hcp-btx__carousel-small-text {
          font-size: 14px;
          line-height: var(--emu-common-line-heights-narrow-large);
          font-weight: var(--emu-common-font-weight-light);
          margin-bottom: var(--emu-common-spacing-medium);
          letter-spacing: -0.2px;
        }

        .hcp-btx__card-link {
          a {
            &::after {
              background-image: url('./assets/images/arrow-light.png');
            }
          }
        }

        .aaaem-title {
          .cmp-title__text {
            line-height: normal;

            @include mq('large') {
              line-height: 28px;
            }
          }
        }

        .aaaem-title,
        .aaaem-text {
          color: var(--emu-common-colors-white);
        }
      }
    }

    &__education-card {
      &-text {
        padding-left: 33px;
        top: 0px;
        width: 100%;

        @include mq('large') {
          padding-right: 49px;
        }

        > div {
          &.text {
            position: absolute;
            top: 22px;
          }

          &.title {
            position: absolute;
            top: 56px;
          }
        }

        .aaaem-title {
          .cmp-title__text {
            color: var(--emu-semantic-colors-primary-purple-300);
            line-height: normal;
          }
        }

        .aaaem-text {
          color: var(--emu-common-colors-white);
          margin-bottom: 15px;
          color: var(--emu-semantic-colors-primary-purple-200);
          font-weight: var(--emu-common-font-weight-regular);
        }
      }

      &-wrapper {
        position: relative;

        @include mq('large') {
          width: 282px;
        }
      }

      &-link {
        a {
          &::before {
            content: '';
            position: absolute;
            display: inline-block;
            width: 100%;
            height: 100%;
            top: var(--emu-common-spacing-none);
            left: var(--emu-common-spacing-none);
          }

          &::after {
            content: '';
            position: absolute;
            min-width: 58px;
            min-height: 58px;
            padding: 21px 21px 20px;
            top: 21px;
            background-color: var(--emu-semantic-colors-primary-purple-300);
            border-radius: 50%;
            right: 15px;
          }

          img {
            position: absolute;
            top: 42px;
            right: 36px;
            z-index: 1;
          }
        }
      }
    }

    &__news-card {
      &-wrapper {
        padding-top: 27px;
        padding-right: var(--emu-common-spacing-large);
        padding-bottom: var(--emu-common-spacing-large);
        padding-left: var(--emu-common-spacing-large);
        background-color: var(--emu-common-colors-white);

        @include mq('large') {
          padding-right: 28px;
          padding-bottom: 30px;
          padding-left: 30px;
          width: 570px; // as per figma
        }

        .aaaem-title {
          color: var(--emu-semantic-colors-primary-purple-400);
        }

        .aaaem-text p {
          color: var(--emu-semantic-colors-primary-purple-400);
        }
      }

      &-title {
        display: flex;
        justify-content: space-between;

        .aaaem-title .cmp-title__text {
          line-height: normal;

          br {
            @include mq('large') {
              display: none;
            }
          }

          .cmp-title__link {
            color: var(--emu-semantic-colors-primary-purple-400);
            text-decoration: none;
          }
        }

        .aaaem-text p {
          padding-top: var(--emu-common-spacing-xxs);

          @include mq('large') {
            padding-top: 1px;
          }

          a {
            color: var(--emu-semantic-colors-primary-purple-400);
            font-weight: var(--emu-common-font-weight-regular);
            text-decoration: none;

            img {
              padding-left: 6px;
            }
          }
        }
      }

      &-list {
        padding-top: 15px;

        .news {
          &__wrapper {
            padding-top: 11px;
          }

          &__top-section {
            display: none;
          }

          &__main {
            display: flex;
            gap: 15px;
            flex-direction: column;

            @include mq('large') {
              gap: var(--emu-common-spacing-small);
            }
          }

          &__article {
            display: block;

            &:not(:last-child) {
              border-bottom: var(--emu-common-border-width-thin) solid
                rgba(0, 0, 0, 0.3);
              padding-bottom: var(--emu-common-spacing-medium);

              @include mq('large') {
                border-bottom: none;
                padding-bottom: var(--emu-common-spacing-none);
              }
            }

            &-poster-image {
              display: none;
            }

            &-content {
              width: auto;
              max-width: none;

              .article-tags__wrapper {
                display: none;
              }
            }

            &-title {
              margin-bottom: var(--emu-common-spacing-none);
              margin-top: var(--emu-common-spacing-none);
              position: relative;

              h4 {
                font-size: var(--emu-common-font-sizes-wide-medium);
                line-height: 21px;
                font-weight: var(--emu-common-font-weight-regular);
                color: var(--emu-semantic-colors-primary-purple-400);
                letter-spacing: normal;
                padding-left: 34px;

                @include mq('large') {
                  padding-left: var(--emu-common-spacing-large);
                }

                &::before {
                  content: '';
                  background-image: url('./assets/images/news.png');
                  display: block;
                  width: var(--emu-common-sizing-small);
                  height: 14px;
                  position: absolute;
                  left: var(--emu-common-spacing-xxs);
                  top: 5px;

                  @include mq('large') {
                    left: var(--emu-common-spacing-none);
                  }
                }
              }
            }
          }
        }
      }
    }

    &__img-text-card {
      .aaaem-title {
        color: var(--emu-common-colors-white);
        margin-bottom: 10px;

        .cmp-title__text {
          font-size: 18px;
          line-height: normal;
        }
      }

      .aaaem-text {
        color: var(--emu-semantic-colors-primary-purple-200);
      }

      &-wrapper {
        display: flex;
        flex-direction: row;
        padding-top: 25px;
        padding-right: 30px;
        padding-bottom: 26px;
        padding-left: 30px;
        background-color: var(--emu-semantic-colors-primary-purple-300);

        @include mq('large') {
          width: 309px; // to match the design.
        }

        > .image {
          width: var(--emu-common-sizing-small);
          margin-right: 11px;
        }

        > .container {
          flex: 1;
        }
      }
    }

    &__inner-carousel {
      padding: var(--emu-common-spacing-none);
      opacity: 1;
      position: relative;

      @include mq('large') {
        max-width: 507px; // to match the design.
        margin-bottom: var(--emu-common-spacing-none);
      }

      .aaaem-carousel {
        &__action {
          border: none;
          width: 10px; // As per the design.
          height: var(--emu-common-sizing-xs); // As per the design.

          &-prev {
            transform: translateX(-4px);
          }

          svg {
            top: 5px;
          }
        }

        &__actions {
          width: calc(100% - 64px); // 32 px on each side
          bottom: 44px;
          top: auto;
          justify-content: space-between;
          left: 50%;
          transform: translateX(-50%);

          @include mq('large') {
            left: auto;
            transform: none;
            right: 30px;
            width: calc(50% - 58px); // to match the design. 29px on each side
          }
        }

        &__indicators {
          top: auto;
          bottom: 33px;
          width: calc(100% - 92px); // to match the design. 46px on each side
          top: auto;
          bottom: 33px;
          left: 50%;
          transform: translateX(-50%);

          @include mq('large') {
            left: auto;
            transform: none;
            right: 43px;
            width: calc(50% - 86px); // to match the design. 43px on each side
          }
        }
      }

      &-container {
        display: flex;
        flex-direction: column;
        border-radius: var(--emu-common-border-radius-large);
        overflow: hidden;
        background-color: var(--emu-semantic-colors-primary-purple-100);

        @include mq('large') {
          flex-direction: row;
        }

        > div {
          flex: 1;

          &.embed {
            .plyr {
              &__video-wrapper {
                max-height: 397px; // to match the design.
                height: 100%;

                @include mq('large') {
                  max-height: 298px;
                }
              }

              &__video-embed iframe {
                top: -11px;
              }

              &__controls {
                justify-content: flex-start;

                &__item {
                  display: none;
                }

                .plyr__volume {
                  display: flex;
                }
              }
            }
          }
        }
      }

      &-content {
        padding-top: 28px;
        padding-right: var(--emu-common-spacing-large);
        padding-bottom: 41px;
        padding-left: var(--emu-common-spacing-large);
        min-height: 233px; // to match the design.
        background-color: var(--emu-semantic-colors-primary-purple-100);

        @include mq('large') {
          padding-right: 29px;
          padding-left: 30px;
          min-height: 298px;
        }

        .aaaem-title {
          margin-bottom: 12px;

          .cmp-title__text {
            color: var(--emu-semantic-colors-primary-purple-400);
            font-size: 18px;
            font-weight: var(--emu-common-font-weight-bold);
            line-height: normal;
            letter-spacing: -0.27px; // As per the design.
          }
        }

        .aaaem-text {
          color: var(--emu-semantic-colors-secondary-dark-gray-200);
          font-size: 16px;
          font-weight: var(--emu-common-font-weight-light);
          line-height: normal;
        }
      }
    }
  }

  .references.text {
    &.references--top {
      margin-top: 46px;
      margin-bottom: 9px;

      @include mq('large') {
        margin-top: 96px;
        margin-bottom: 75px;
      }
    }
  }
}

// author view styles
.hcp-btx__text-card-bottom {
  @include aem-editor-view {
    margin-left: var(--emu-common-spacing-none) !important;
  }
}
